export const environment = {
    firebase: {
        apiKey: "AIzaSyBArOqN3qV9iZI0i2raAmYhxvhsFAvpNOY",
        authDomain: "otb-c-4b0c7.firebaseapp.com",
        databaseURL: "https://otb-c-4b0c7-default-rtdb.firebaseio.com",
        projectId: "otb-c-4b0c7",
        storageBucket: "otb-c-4b0c7.appspot.com",
        messagingSenderId: "541856932009",
        appId: "1:541856932009:web:4f5fb86b9a91cb494e89b8"
      }
};
