import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, Component, Inject, ViewChild } from '@angular/core';
import { AngularFireDatabase, AngularFireObject } from '@angular/fire/compat/database';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Papa } from 'ngx-papaparse';
import { BulkDeleteDialog, DeleteOrderDialog, UpdateInvoicedDate, UpdateQuantityDialog } from '../on-order/on-order.component';
import { FoodMerchDataSource, FoodMerchItem } from './food-merch-datasource';

@Component({
  selector: 'app-food-merch',
  templateUrl: './food-merch.component.html',
  styleUrls: ['./food-merch.component.scss']
})
export class FoodMerchComponent implements AfterViewInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatTable) table!: MatTable<any>;
  dataSource: MatTableDataSource<FoodMerchDataSource>;
  foodMerch: AngularFireObject<any>;
  selection = new SelectionModel<any>(true, []);
  search: string ='';

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['select', 'reorder','orderDate', 'invoiced', 'received', 'cases', 'brand', 'product', 'type', 'qtyCase', 'caseCost', 'unitCost', 'unitPrice', 'marginDollar', 'marginPercent'];
  tempCacheQuantity: any;
  cacheInvoicedDate: any;

  constructor(private db: AngularFireDatabase, public papa: Papa, public dialog: MatDialog) {
    this.dataSource = new MatTableDataSource();
    this.foodMerch = db.object('foodMerch');
  }

  updateCacheQuantity(row: any) {
    this.tempCacheQuantity = row.qtyCase;
  }

  reorderProduct(row: any) {
    const dialogRef = this.dialog.open(ReOrderDialog, {
      width: '500px',
      data: {
        row: row
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        let newProduct = Object.assign({}, row);
        newProduct.orderDate = new Date().toLocaleDateString(); 
        newProduct.invoiced = null;   
        this.dataSource.data.push(newProduct);
        this.foodMerch.set(this.dataSource.data);

      }
    });
  }

  updateInvoicedDate(row: any) {

    if (this.cacheInvoicedDate != row.invoiced) {
      const dialogRef = this.dialog.open(UpdateInvoicedDate, {
        width: '500px',
        data: {
          row: row
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.foodMerch.set(this.dataSource.data);
        } else {
          row.invoiced = this.cacheInvoicedDate;
        }
      });
    }
  }

  updateCacheInvoicedDate(row: any) {
    this.cacheInvoicedDate = row.invoiced;
  }

  updateQuantity(row: any) {
    if (row.qtyCase !== this.tempCacheQuantity) {
      const dialogRef = this.dialog.open(UpdateQuantityDialog, {
        width: '500px',
        data: {
          row: row
        }
      });

      dialogRef.afterClosed().subscribe(result => {

        if (result) {
          this.foodMerch.set(this.dataSource.data);
        } else {
          row.qtyCase = this.tempCacheQuantity;
        }
      });
    }
  }

  public changeListener(event: any){

    let files = event.target.files;

    if(files && files.length > 0) {
       let file : any = files.item(0); 
         let reader: FileReader = new FileReader();
         reader.readAsText(file);
         reader.onload = (e) => {
            let csv: string = reader.result as string;
            
            this.papa.parse(csv, {
              header: true,
              complete: (result: any) => {
                  result.data.forEach((row:any, i: any) => {

                    if (row.qtyCase && row.caseCost) {
                      row.unitCost = (row.qtyCase / row.caseCost.split('$')[1]).toFixed(2);
                
                    } else {
                      row.unitCost = 0;
                    }

                    if(row.unitPrice && row.unitCost) {
                      row.marginDollar = row.unitPrice.split('$')[1] - row.unitCost;
                      
                      if(row.marginDollar && row.unitPrice) {
                        row.marginPercent = (row.marginDollar / row.unitPrice.split('$')[1]);

                        if(row.marginPercent === Infinity || row.marginPercent === -Infinity) {
                          row.marginPercent = 0;
                        }
                      }
                    }

                    if(i === result.data.length - 1) {
                      this.dataSource.data = this.dataSource.data.concat(result.data);
                      this.foodMerch.set(this.dataSource.data);
                     }
                  });
              }
          });

         }
      }
  }


  updateRecieved() {
    this.foodMerch.set(this.dataSource.data);
  }

  applyFilter(search: string) {

    this.dataSource.filter = search.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  deleteRow(row: any) {
    const dialogRef = this.dialog.open(DeleteOrderDialog, {
      width: '250px',
      data: {
        row: row
      }
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result) {
        this.deleteProduct(row);
      }
    });
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  addAProductToPackaged() {
    const dialogRef = this.dialog.open(AddAProductToFoodMerch);

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.dataSource.data.push(result);
        this.foodMerch.set(this.dataSource.data);
      }
    });
  }



  bulkDelete() {
    const dialogRef = this.dialog.open(BulkDeleteDialog, {
      width: '500px',
      data: {
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.selection.selected.forEach((selected: any) => {
          this.deleteProduct(selected);
        })
      }
    });
  }

  deleteProduct(row: any) {
    let ind = this.dataSource.data.findIndex(obj => { return obj === row });

    if (ind !== -1) {
      this.dataSource.data.splice(ind, 1);
      this.table.dataSource = this.dataSource.data;
      this.foodMerch.set(this.dataSource.data);
    }
  }


  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;

    this.foodMerch.valueChanges().subscribe((value: any[]) => {
      this.dataSource.data = value;
      this.table.dataSource = this.dataSource;
      this.dataSource.paginator = this.paginator;
    })
  }
}


@Component({
  selector: 're-order-dialog',
  templateUrl: 're-order-dialog.html',
})
export class ReOrderDialog {
  constructor(public dialogRef: MatDialogRef<DeleteOrderDialog>, @Inject(MAT_DIALOG_DATA) public data: any) { }
}

@Component({
  selector: 'add-product-to-foodMerch',
  templateUrl: 'add-a-product-to-foodMerch.html',
  styleUrls: ['./add-a-product-foodMerch.component.scss']
})

export class AddAProductToFoodMerch {
  foodMerchForm = new FormGroup({
    orderDate: new FormControl('', [Validators.required]),
    received: new FormControl('', [Validators.required]),
    invoiced: new FormControl('', [Validators.required]),
    cases: new FormControl('', [Validators.required]),
    brand: new FormControl('', [Validators.required]),
    product: new FormControl('', [Validators.required]),
    type: new FormControl('', [Validators.required]),
    qtyCase: new FormControl<number>(0, [Validators.required]),
    caseCost: new FormControl<number>(0, [Validators.required]),
    unitCost: new FormControl<number>(0, [Validators.required]),
    unitPrice: new FormControl<number>(0, [Validators.required]),
    marginDollar: new FormControl<number>(0, [Validators.required]),
    marginPercent: new FormControl<number>(0, [Validators.required]),
  });

  refTable = [
    { name: "sixtel", value: 595 },
    { name: "half", value: 1786 },
    { name: "quarter", value: 893 },
    { name: "30l", value: 913 },
    { name: "50l", value: 1521 },
    { name: "24case", value: 24 },
    { name: "18case", value: 18 },
    { name: "12case", value: 12 },
    { name: "6case", value: 6 }];

  pourSelection = [
    { name: "Tulip", value: 12 },
    { name: "Pint", value: 17 },
    { name: "Nonic", value: 17 },
    { name: "8oz", value: 8 },
    { name: "Wine", value: 8 },
    { name: "Bottle/Can", value: 1 }
  ]

  constructor(public dialogRef: MatDialogRef<AddAProductToFoodMerch>, @Inject(MAT_DIALOG_DATA) public data: any) {

  }

  updateUnitCost() {
    if (this.foodMerchForm.controls.qtyCase.value && this.foodMerchForm.controls.caseCost.value) {
      this.foodMerchForm.controls.unitCost.setValue(this.foodMerchForm.controls.qtyCase.value / this.foodMerchForm.controls.caseCost.value);

    } else {
      this.foodMerchForm.controls.unitCost.setValue(0);
    }
  }

  updateMarginDollar() {
    if(this.foodMerchForm.controls.unitPrice.value && this.foodMerchForm.controls.unitCost.value) {
      this.foodMerchForm.controls.marginDollar.setValue(this.foodMerchForm.controls.unitPrice.value - this.foodMerchForm.controls.unitCost.value)
      
      this.updateMarginPercent();
    }
  }

  updateMarginPercent() {
    if(this.foodMerchForm.controls.marginDollar.value && this.foodMerchForm.controls.unitPrice.value) {
      this.foodMerchForm.controls.marginPercent.setValue(this.foodMerchForm.controls.marginDollar.value / this.foodMerchForm.controls.unitPrice.value)
    }
  }



  onSubmit() {
    this.dialogRef.close(this.foodMerchForm.value);
  }
}
