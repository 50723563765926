import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FoodMerchComponent } from './food-merch/food-merch.component';
import { KickedComponent } from './kicked/kicked.component';
import { MasterComponent } from './master/master.component';
import { OnDeckComponent } from './on-deck/on-deck.component';
import { OnOrderComponent } from './on-order/on-order.component';
import { OnTapComponent } from './on-tap/on-tap.component';
import { PackagedComponent } from './packaged/packaged.component';
import { SummaryComponent } from './summary/summary.component';
import { WineComponent } from './wine/wine.component';

const routes: Routes = [
  { 
    path:  'on-order',
    component:  OnOrderComponent,
  },
  {
    path: 'on-deck',
    component: OnDeckComponent
  },{
    path: 'on-tap',
    component: OnTapComponent
  },{
    path: 'on-kicked',
    component: KickedComponent
  },{
    path: 'master',
    component: MasterComponent
  },
  {
    path: 'packaged',
    component: PackagedComponent
  },
  {
    path: 'foodMerch',
    component: FoodMerchComponent
  },
  {
    path: 'wine',
    component: WineComponent
  },
  {
    path: 'summary',
    component: SummaryComponent
  },
  { 
    path:  '',
    component:  OnOrderComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
