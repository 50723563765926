import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { AngularFireDatabase, AngularFireObject } from '@angular/fire/compat/database';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { BulkDeleteDialog, BulkUpdateDialog, UpdateInvoicedDate, UpdateOrderDialog, UpdateQuantityDialog } from '../on-order/on-order.component';
import { OnDeckDataSource, OnDeckItem } from './on-deck-datasource';

@Component({
  selector: 'app-on-deck',
  templateUrl: './on-deck.component.html',
  styleUrls: ['./on-deck.component.scss']
})
export class OnDeckComponent implements AfterViewInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatTable) table!: MatTable<any>;
  dataSource: MatTableDataSource<OnDeckDataSource>;
  onOrder: AngularFireObject<any>;
  onTap: AngularFireObject<any>;
  onDeck: AngularFireObject<any>;
  onKicked: AngularFireObject<any>;
  onOrderData: any = [];
  onTapData: any = [];
  onKickedData: any = [];
  selection = new SelectionModel<any>(true, []);
  search: string ='';
  refTable = [
    { name: "sixtel", value: 595, kegCount: 1 },
    { name: "half", value: 1786 , kegCount: 3 },
    { name: "quarter", value: 893, kegCount: 2 },
    { name: "30l", value: 913, kegCount: 1 },
    { name: "50l", value: 1521, kegCount: 3 },
    { name: "24case", value: 24, kegCount: 0 },
    { name: "18case", value: 18, kegCount: 0 },
    { name: "12case", value: 12, kegCount: 0 },
    { name: "6case", value: 6, kegCount: 0 }];


  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['select', 'status', 'orderDate', 'invoiced', 'qty',  'brewery', 'name', 'style', 'abv', 'tastingNote', 'category',
  'format','cost', 'pour',  'unitCost', 'pourPrice', 'pourCost',
    'marginDollar', 'marginPercent', 'tasterPrice', 'tasterCost',
    'tasterMarginDollar', 'tasterMarginPercent', 'kegCount'
  ];
  cacheInvoicedDate: any;
  tempCacheQuantity: any;
  tempSelectionValue: any;
  bulkStatus: any;

  constructor(private db: AngularFireDatabase, public dialog: MatDialog) {
    this.dataSource = new MatTableDataSource();
    this.onOrder = db.object('On Order');
    this.onTap = db.object('On Tap');
    this.onDeck = db.object('On Deck');
    this.onKicked = db.object('Kicked');
  }

  toFixed2($event: any) {
    return ($event) ? $event.toFixed(2) : '';
  }

  calculateKegCount(product: any) {
    product.kegCount = this.refTable.filter(format=> format.name === product.format.toLowerCase())[0].kegCount;
    product.kegCount = (!product.kegCount)? 0: product.kegCount*parseInt(product.qty); 
    return product.kegCount;
  }

  bulkDelete() {
    const dialogRef = this.dialog.open(BulkDeleteDialog, {
      width: '500px',
      data: {

      }
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result) {
        this.selection.selected.forEach((selected: any) => {
          this.deleteProduct(selected);
        })
      }
    });

  }

  updateRecieved() {
    this.onDeck.set(this.dataSource.data);
  }

  bulkUpdateStatus(status: any) {

    if (status) {
      const dialogRef = this.dialog.open(BulkUpdateDialog, {
        width: '500px',
        data: {
          status: status
        }
      });

      dialogRef.afterClosed().subscribe(result => {

        if (result) {
          this.selection.selected.forEach((selected: any, i) => {
            selected.status = status;
            this.updateProduct(selected);

            if(i == this.selection.selected.length - 1) {
              status = '';
            }
            
          })
        }
      });
    }
  }

  updateQuantity(row: any) {

    if (row.qty !== this.tempCacheQuantity) {
      const dialogRef = this.dialog.open(UpdateQuantityDialog, {
        width: '500px',
        data: {
          row: row
        }
      });

      dialogRef.afterClosed().subscribe(result => {

        if (result) {
          this.onDeck.set(this.dataSource.data);
        } else {
          row.qty = this.tempCacheQuantity;
        }
      });
    }
  }

    /** Whether the number of selected elements matches the total number of rows. */
    isAllSelected() {
      const numSelected = this.selection.selected.length;
      const numRows = this.dataSource.data.length;
      return numSelected === numRows;
    }
  
    /** Selects all rows if they are not all selected; otherwise clear selection. */
    toggleAllRows() {
      if (this.isAllSelected()) {
        this.selection.clear();
        return;
      }
  
      this.selection.select(...this.dataSource.data);
    }
  
    /** The label for the checkbox on the passed row */
    checkboxLabel(row?: any): string {
      if (!row) {
        return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
      }
      return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
    }

    applyFilter(search: string) {

      this.dataSource.filter = search.trim().toLowerCase();
  
      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }
    }

  updateCacheStatus(row: any) {
    this.tempSelectionValue = row.status;
  }

  updateStatus(row: any, $index: any) {
    console.log(row, $index);

    if (row.status !== this.tempSelectionValue) {
      const dialogRef = this.dialog.open(UpdateOrderDialog, {
        width: '500px',
        data: {
          row: row
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.updateProduct(row);
        } else {
          row.status = this.tempSelectionValue;
        }
      });
    }
  }

  updateProduct(row: any) {
    /** Add to other status table */
    if (row.status === 'On Order') {
      row.orderDate = new Date().toISOString().split('T')[0];
      this.onOrderData.push(row);
      this.onOrder.set(this.onOrderData);
      this.deleteProduct(row);

    } else if (row.status === 'On Tap') {
      row.tapDate = new Date().toISOString().split('T')[0];
      row.qty = parseInt(row.qty) - 1; 

      if(row.qty > 0) {
        this.onTapData.push(row);
        this.onTap.set(this.onTapData);

        row.status = "On Deck";
        this.onDeck.set(this.dataSource.data);
      } else {
        this.onTapData.push(row);
        this.onTap.set(this.onTapData);
        this.deleteProduct(row);
      }

 
    } else if (row.status === 'Kicked') {
      row.kickedDate = new Date().toISOString().split('T')[0];
      this.onKickedData.push(row);
      this.onKicked.set(this.onKickedData);
      this.deleteProduct(row);
    }
  }

  deleteProduct(row: any) {
    let ind = this.dataSource.data.findIndex(obj => { return obj === row });

    if (ind !== -1) {
      this.dataSource.data.splice(ind, 1);
      this.table.dataSource = this.dataSource.data;
      this.onDeck.set(this.dataSource.data);
    }
  }


  updateCacheQuantity(row: any) {
    this.tempCacheQuantity = row.qty;
  }

  updateInvoicedDate(row: any) {

    if (this.cacheInvoicedDate != row.invoiced) {
      const dialogRef = this.dialog.open(UpdateInvoicedDate, {
        width: '500px',
        data: {
          row: row
        }
      });

      dialogRef.afterClosed().subscribe(result => {

        if (result) {
          this.onDeck.set(this.dataSource.data);
        } else {
          row.invoiced = this.cacheInvoicedDate;
        }
      });
    }
  }

  updateCacheInvoicedDate(row: any) {
    this.cacheInvoicedDate = row.invoiced;
  }

  convertToPercent($event: any) {
    return ($event) ? (parseFloat($event) * 100).toFixed(2) : '';
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;

    this.onDeck.valueChanges().subscribe((value: any[]) => {
      console.log("ON DECK", value);
      this.dataSource.data = value;
      this.table.dataSource = this.dataSource;
      this.dataSource.paginator = this.paginator;
    })

    this.onTap.valueChanges().subscribe((value: any[]) => {
      this.onTapData = value;
      
    })
    this.onOrder.valueChanges().subscribe((value: any[]) => {
      this.onOrderData = value;
    })

    this.onKicked.valueChanges().subscribe((value: any[]) => {
      this.onKickedData = value;
    })

  }
}
