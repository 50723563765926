import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, Component, Inject, ViewChild } from '@angular/core';
import { AngularFireDatabase, AngularFireObject } from '@angular/fire/compat/database';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { BulkDeleteDialog, DeleteOrderDialog } from '../on-order/on-order.component';
import { MasterDataSource, MasterItem } from './master-datasource';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Papa } from 'ngx-papaparse';

@Component({
  selector: 'app-master',
  templateUrl: './master.component.html',
  styleUrls: ['./master.component.scss']
})
export class MasterComponent implements AfterViewInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatTable) table!: MatTable<any>;
  dataSource: MatTableDataSource<MasterDataSource>;
  master: AngularFireObject<any>;
  selection = new SelectionModel<any>(true, []);
  search: string ='';

  refTable = [
    { name: "sixtel", value: 595 },
    { name: "half", value: 1786 },
    { name: "quarter", value: 893 },
    { name: "30l", value: 913 },
    { name: "50l", value: 1521 },
    { name: "24case", value: 24 },
    { name: "18case", value: 18 },
    { name: "12case", value: 12 },
    { name: "6case", value: 6 }];

  pourSelection = [
    { name: "Tulip", value: 12 },
    { name: "Pint", value: 17 },
    { name: "Nonic", value: 17 },
    { name: "8oz", value: 8 },
    { name: "Wine", value: 8 },
    { name: "Bottle/Can", value: 1 }
  ]


  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['select', 'brewery', 'name', 'style',  'abv', 'tastingNotes', 'lightAleLager',
     'format', 'cost', 'pour', 'unitCost', 'pourPrice', 'pourCost', 'marginDollar', 'marginPercent', 'tasterPrice', 'tasterCost', 'tasterMarginDollar', 'tasterMarginPercent'];

  convertToPercent($event: any) {
    return ($event) ? (parseFloat($event) * 100 / 100).toFixed(2) : '';
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  deleteRow(row: any) {
    const dialogRef = this.dialog.open(DeleteOrderDialog, {
      width: '250px',
      data: {
        row: row
      }
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result) {
        this.deleteProduct(row);
      }
    });
  }

  deleteProduct(row: any) {
    let ind = this.dataSource.data.findIndex(obj => { return obj === row });

    if (ind !== -1) {
      this.dataSource.data.splice(ind, 1);
      this.table.dataSource = this.dataSource.data;
      this.master.set(this.dataSource.data);
    }
  }

  applyFilter(search: string) {

    this.dataSource.filter = search.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }


  bulkDelete() {
    const dialogRef = this.dialog.open(BulkDeleteDialog, {
      width: '500px',
      data: {
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.selection.selected.forEach((selected: any) => {
          this.deleteProduct(selected);
        })
      }
    });
  }

  toFixed2($event: any) {
    return ($event) ? Number($event).toFixed(2) : '';
  }

  constructor(private db: AngularFireDatabase, public dialog: MatDialog, public papa: Papa) {
    this.dataSource = new MatTableDataSource();
    this.master = db.object('master');
  }

  public changeListener(event: any) {

    let files = event.target.files;

    if (files && files.length > 0) {
      let file: any = files.item(0);
      let reader: FileReader = new FileReader();
      reader.readAsText(file);
      reader.onload = (e) => {
        let csv: string = reader.result as string;

        this.papa.parse(csv, {
          header: true,
          complete: (result: any) => {

            result.data.forEach((row: any, i: any) => {

              if (row.format && parseInt(row.cost) !== 0) {
                let selectedFormat = this.refTable.filter(format => format.name === row.format.toLowerCase())[0]
                let cost: any = row.cost;
                let unitCost: any = (cost / selectedFormat.value).toFixed(2);
                row.unitCost = unitCost;
                //  this.updateTasterCost();

                if (row.unitCost) {
                  row.tasterCost = (row.unitCost * 5).toFixed(2);
                }

                if (row.pour) {
                  //   this.updatePourCost();

                  if (row.unitCost && row.pour) {
                    let selectedPour = this.pourSelection.filter(pour => pour.name === row.pour)[0];
                    let cost: any = row.cost;
                    row.pourCost = (row.unitCost * selectedPour.value).toFixed(2);

                    if (row.pourPrice) {
                      // this.updateMarginDollar();
                      if (row.pourPrice && row.pourCost) {
                        row.marginDollar = (row.pourPrice - row.pourCost).toFixed(2);

                        // this.updateMarginPercent();
                        if (row.pourPrice && row.marginDollar) {
                          row.marginPercent = (row.marginDollar / row.pourPrice).toFixed(2);
                        }
                      }
                    }
                  }
                }
              } else {
                row.unitCost = 0;
              }

              console.log(row);

              if (i === result.data.length - 1) {
                this.dataSource.data = this.dataSource.data.concat(result.data);
                this.master.set(this.dataSource.data);
              }
            })
            //  this.dataSource.data = this.dataSource.data.concat(result.data);
            //  this.master.set(this.dataSource.data);
          }
        });

      }
    }
  }

  addAProduct() {
    const dialogRef = this.dialog.open(AddAProductToMaster);

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.dataSource.data.push(result);
        this.master.set(this.dataSource.data);
      }
    });

  }


  ngAfterViewInit(): void {

    this.master.valueChanges().subscribe((value: any[]) => {
      this.dataSource.data = value;
      this.table.dataSource = this.dataSource;
      this.dataSource.paginator = this.paginator;
    })
  }
}

@Component({
  selector: 'add-product-to-master',
  templateUrl: 'add-a-product-to-master.html',
  styleUrls: ['./add-a-product-master.component.scss']
})
export class AddAProductToMaster {
  masterForm = new FormGroup({
    abv: new FormControl('', [Validators.required]),
    name: new FormControl('', [Validators.required]),
    brewery: new FormControl('', [Validators.required]),
    style: new FormControl('', [Validators.required]),
    cost: new FormControl<number>(0, [Validators.required]),
    tasterPrice: new FormControl<number>(0, [Validators.required]),
    pourPrice: new FormControl<number>(0, [Validators.required]),
    tastingNotes: new FormControl('', [Validators.required]),
    pourCost: new FormControl<number>(0, [Validators.required]),
    pour: new FormControl('', [Validators.required]),
    format: new FormControl('', [Validators.required]),
    lightAleLager: new FormControl('', [Validators.required]),
    tasterCost: new FormControl<number>(0, [Validators.required]),
    unitCost: new FormControl<number>(0, []),
    marginDollar: new FormControl<number>(0, [Validators.required]),
    marginPercent: new FormControl<number>(0, [Validators.required]),
    tasterMarginPercent: new FormControl<number>(0, [Validators.required]),
    tasterMarginDollar: new FormControl<number>(0, [Validators.required])
  });

  category = [
    {
      type: 'Light Ale/Lager'
    },
    {
      type: 'Light & Malty'
    },
    {
      type: 'Red/Amber'
    }, {
      type: 'Wheat'
    }, {
      type: 'Pale'
    }, {
      type: 'WC IPA'
    }, {
      type: 'WC DIPA+'
    }, {
      type: 'Hazy IPA'
    }, {
      type: 'Dark'
    }, {
      type: 'Imp Dark'
    }, {
      type: 'Nitro'
    }, {
      type: 'Belgian/Farmhouse'
    }, {
      type: 'Sour'
    }, {
      type: 'Water'
    }, {
      type: 'Speciality'
    }, {
      type: 'Cider'
    }, {
      type: 'Kombucha'
    }, {
      type: 'Wine'
    }, {
      type: 'Coffee'
    }
  ]

  refTable = [
    { name: "sixtel", value: 595, kegCount: 1 },
    { name: "half", value: 1786 , kegCount: 3 },
    { name: "quarter", value: 893, kegCount: 2 },
    { name: "30l", value: 913, kegCount: 1 },
    { name: "50l", value: 1521, kegCount: 3 },
    { name: "24case", value: 24, kegCount: 0 },
    { name: "18case", value: 18, kegCount: 0 },
    { name: "12case", value: 12, kegCount: 0 },
    { name: "6case", value: 6, kegCount: 0 }];

    pourSelection = [
      { name: "Tulip", value: 12 },
      { name: "Pint", value: 17 },
      { name: "Nonic", value: 17 },
      { name: "8oz", value: 8 },
      { name: "Wine", value: 8 },
      { name: "Bottle/Can", value: 1 }
    ]

  constructor(public dialogRef: MatDialogRef<AddAProductToMaster>, @Inject(MAT_DIALOG_DATA) public data: any) {

  }

  updateUnitCost() {
    if(this.masterForm.controls.format.value) {
      let name = this.masterForm.controls.format.value;
    }

    if (this.masterForm.controls.format.value && this.masterForm.controls.cost.value !== 0) {
      let selectedFormat = this.refTable.filter(format => format.name === this.masterForm.controls.format.value?.toLowerCase())[0]
      
      let cost: any = this.masterForm.controls.cost.value;
      let unitCost: any = (cost / selectedFormat.value);
      console.log(selectedFormat, unitCost, cost);
      this.masterForm.controls.unitCost.setValue(unitCost);

      this.updateTasterCost();

      if (this.masterForm.controls.pour.value) {
        this.updatePourCost();
      }
    } else {
      this.masterForm.controls.unitCost.setValue(0);
    }
  }

  updatePourCost() {
    if (this.masterForm.controls.unitCost.value && this.masterForm.controls.pour.value) {
      let selectedPour = this.pourSelection.filter(pour => pour.name === this.masterForm.controls.pour.value)[0];
      this.masterForm.controls.pourCost.setValue(this.masterForm.controls.unitCost.value * selectedPour.value);

      console.log(selectedPour, this.masterForm.controls.unitCost.value * selectedPour.value);
      if (this.masterForm.controls.pourPrice.value) {
        this.updateMarginDollar();
      }
    }
  }

  updateMarginDollar() {
    if (this.masterForm.controls.pourPrice.value && this.masterForm.controls.pourCost.value) {
      this.masterForm.controls.marginDollar.setValue(this.masterForm.controls.pourPrice.value - this.masterForm.controls.pourCost.value)

      this.updateMarginPercent();
    }
  }

  updateTasterMarginDollar() {
    if (this.masterForm.controls.tasterPrice.value && this.masterForm.controls.tasterCost.value) {
      this.masterForm.controls.tasterMarginDollar.setValue(this.masterForm.controls.tasterPrice.value - this.masterForm.controls.tasterCost.value)

      this.updateTasterMarginPercent();
    }
  }

  updateTasterMarginPercent() {
    if (this.masterForm.controls.tasterPrice.value && this.masterForm.controls.tasterMarginDollar.value) {
      this.masterForm.controls.tasterMarginPercent.setValue(this.masterForm.controls.tasterMarginDollar.value / this.masterForm.controls.tasterPrice.value)
    }
  }

  updateMarginPercent() {
    if (this.masterForm.controls.pourPrice.value && this.masterForm.controls.marginDollar.value) {
      this.masterForm.controls.marginPercent.setValue(this.masterForm.controls.marginDollar.value / this.masterForm.controls.pourPrice.value)
    }
  }

  updateTasterCost() {
    if (this.masterForm.controls.unitCost.value) {
      this.masterForm.controls.tasterCost.setValue(this.masterForm.controls.unitCost.value * 5);

      this.updateTasterMarginDollar();
    }
  }

  onSubmit() {
    this.dialogRef.close(this.masterForm.value);
  }
}

