
  <mat-form-field class="search-product">
    <mat-label>Search</mat-label>
    <input matInput (keyup)="applyFilter(search)" [(ngModel)]="search"
      placeholder="Search by any column. Eg: cost, margin, date" #input>
    <button *ngIf="search" matSuffix mat-icon-button aria-label="Clear" (click)="search=''; applyFilter(search)">
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>

<button class="group-button" mat-button [matMenuTriggerFor]="menu">Actions</button>
<mat-menu #menu="matMenu">
  <button mat-menu-item (click)="addAProductToPackaged()">Add A Product <mat-icon>plus_one</mat-icon></button>
  <button mat-menu-item (click)="bulkDelete()">delete <mat-icon>delete</mat-icon></button>
  <button mat-menu-item (click)="fileInput.click()">Import CSV</button>
  <input hidden (change)="changeListener($event)" #fileInput type="file">
</mat-menu>

<div class="mat-elevation-z8">
  <table mat-table class="table-responsive" matSort aria-label="Elements">

    <!-- Checkbox Column -->
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox (change)="$event ? toggleAllRows() : null" [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
          [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
        </mat-checkbox>
      </td>
    </ng-container>

    <!-- Delete Column -->
    <ng-container matColumnDef="delete">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Delete</th>
      <td mat-cell *matCellDef="let row;let i = index">
        <button (click)="deleteRow(row)" mat-button color="warn"><span class="material-icons">delete</span>
        </button>
      </td>
    </ng-container>

    <!-- Reorder Column -->
    <ng-container matColumnDef="reorder">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Reorder</th>
      <td mat-cell *matCellDef="let row;let i = index">
        <button (click)="reorderProduct(row)" mat-button color="warn"><span class="material-icons">update</span>
        </button>
      </td>
    </ng-container>

    <!-- orderDate Column -->
    <ng-container matColumnDef="orderDate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Order Date</th>
      <td mat-cell *matCellDef="let row"><b> {{row.orderDate | date: 'MMM d, y'}} </b></td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="brand">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Brand</th>
      <td mat-cell *matCellDef="let row">{{row.brand}}</td>
    </ng-container>

    <!-- Invoiced Column -->
    <ng-container matColumnDef="invoiced">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Invoiced</th>
      <td mat-cell *matCellDef="let row">
        <mat-form-field>
          <input matInput type="text" (click)="updateCacheInvoicedDate(row)" (blur)="updateInvoicedDate(row)"
            [(ngModel)]="row.invoiced">
        </mat-form-field>
      </td>
    </ng-container>
    <!-- Invoiced Column -->
    <ng-container matColumnDef="received">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Received</th>

      <td mat-cell *matCellDef="let row">
        <mat-slide-toggle (change)="updateRecieved()" [(ngModel)]="row.received"></mat-slide-toggle>
      </td>
    </ng-container>

    <!-- qty Column -->
    <ng-container matColumnDef="qtyCase">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Quantity/Case</th>
      <td mat-cell *matCellDef="let row">
        <mat-form-field class="quantity-form-field">
          <input matInput type="text" (click)="updateCacheQuantity(row)" (blur)="updateQuantity(row)"
            [(ngModel)]="row.qtyCase">
        </mat-form-field>
      </td>
    </ng-container>

    <!-- brewery Column -->
    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
      <td mat-cell *matCellDef="let row">{{row.type}}</td>
    </ng-container>


    <!-- format Column -->
    <ng-container matColumnDef="product">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Product</th>
      <td mat-cell *matCellDef="let row">{{row.product}}</td>
    </ng-container>

    <!-- style Column -->
    <ng-container matColumnDef="caseCost">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Case Cost</th>
      <td mat-cell *matCellDef="let row">{{row.caseCost}}</td>
    </ng-container>

    <!-- style Column -->
    <ng-container matColumnDef="cases">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Cases</th>
      <td mat-cell *matCellDef="let row">{{row.cases}}</td>
    </ng-container>

    <!-- style Column -->
    <ng-container matColumnDef="unitCost">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Unit Cost</th>
      <td mat-cell *matCellDef="let row">{{row.unitCost}}</td>
    </ng-container>

    <!-- style Column -->
    <ng-container matColumnDef="unitPrice">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Unit Price</th>
      <td mat-cell *matCellDef="let row">{{row.unitPrice}}</td>
    </ng-container>

    <!-- style Column -->
    <ng-container matColumnDef="marginDollar">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Margin $</th>
      <td mat-cell *matCellDef="let row">{{row.marginDollar}}</td>
    </ng-container>

    <!-- style Column -->
    <ng-container matColumnDef="marginPercent">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>margin %</th>
      <td mat-cell *matCellDef="let row">{{row.marginPercent}}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <mat-paginator #paginator [length]="dataSource.data.length" [pageIndex]="0" [pageSize]="10"
    [pageSizeOptions]="[5, 10, 20]" aria-label="Select page">
  </mat-paginator>
</div>