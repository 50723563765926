import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, Component, Inject, ViewChild } from '@angular/core';
import { AngularFireDatabase, AngularFireObject } from '@angular/fire/compat/database';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { BulkDeleteDialog, BulkUpdateDialog, UpdateOrderDialog, UpdateQuantityDialog } from '../on-order/on-order.component';
import { OnTapDataSource, OnTapItem } from './on-tap-datasource';

@Component({
  selector: 'app-on-tap',
  templateUrl: './on-tap.component.html',
  styleUrls: ['./on-tap.component.scss']
})
export class OnTapComponent implements AfterViewInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatTable) table!: MatTable<any>;
  dataSource: MatTableDataSource<OnTapDataSource>;
  onOrder: AngularFireObject<any>;
  onTap: AngularFireObject<any>;
  onDeck: AngularFireObject<any>;
  onKicked: AngularFireObject<any>;
  onOrderData: any = [];
  onDeckData: any = [];
  onKickedData: any = [];
  selection = new SelectionModel<any>(true, []);
  search: string ='';

   /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
   displayedColumns = ['select', 'status', 'tap', 'tapDate', 'brewery', 'name', 'style', 'abv', 'tastingNotes',
    'category','format' ,'cost', 'pour', 'unitCost', 'pourPrice','pourCost','marginDollar','marginPercent',
    'tasterPrice', 'tasterCost', 'tasterMarginDollar', 'tasterMarginPercent'];
  tempSelectionValue: any;
  tempCacheQuantity: any;
  bulkStatus: any;

  constructor(private db: AngularFireDatabase, public dialog: MatDialog) {
    this.dataSource = new MatTableDataSource();
    this.onOrder = db.object('On Order');
    this.onTap = db.object('On Tap');
    this.onDeck = db.object('On Deck');
    this.onKicked = db.object('Kicked');
  }

  toFixed2($event: any) {
    return ($event) ? parseFloat($event).toFixed(2) : '';
  }

  bulkDelete() {
    const dialogRef = this.dialog.open(BulkDeleteDialog, {
      width: '500px',
      data: {

      }
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result) {
        this.selection.selected.forEach((selected: any) => {
          this.deleteProduct(selected);
        })
      }
    });

  }

  bulkUpdateStatus(status: any) {

    if (status) {
      const dialogRef = this.dialog.open(BulkUpdateDialog, {
        width: '500px',
        data: {
          status: status
        }
      });

      dialogRef.afterClosed().subscribe(result => {

        if (result) {
          this.selection.selected.forEach((selected: any, i) => {
            selected.status = status;
            this.updateProduct(selected);

            if(i == this.selection.selected.length - 1) {
              status = '';
            }
            
          })
        }
      });
    }
  }

  updateCacheStatus(row: any) {
    this.tempSelectionValue = row.status;
  }

  updateCacheQuantity(row: any) {
    this.tempCacheQuantity = row.tap;
  }

  updateQuantity(row: any) {

    if (row.tap !== this.tempCacheQuantity) {
      const dialogRef = this.dialog.open(UpdateTapComponent, {
        width: '500px',
        data: {
          row: row
        }
      });

      dialogRef.afterClosed().subscribe(result => {

        if (result) {
          this.onTap.set(this.dataSource.data);
        } else {
          row.tap = this.tempCacheQuantity;
        }
      });
    }
  }

      /** Whether the number of selected elements matches the total number of rows. */
      isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;
        return numSelected === numRows;
      }
    
      /** Selects all rows if they are not all selected; otherwise clear selection. */
      toggleAllRows() {
        if (this.isAllSelected()) {
          this.selection.clear();
          return;
        }
    
        this.selection.select(...this.dataSource.data);
      }
    
      /** The label for the checkbox on the passed row */
      checkboxLabel(row?: any): string {
        if (!row) {
          return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
        }
        return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
      }

  updateStatus(row: any, $index: any) {

    if (row.status !== this.tempSelectionValue) {
      const dialogRef = this.dialog.open(UpdateOrderDialog, {
        width: '500px',
        data: {
          row: row
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.updateProduct(row);
        } else {
          row.status = this.tempSelectionValue;
        }
      });
    }
  }

  updateProduct(row: any) {
    /** Add to other status table */
    if (row.status === 'On Order') {
      row.orderDate = new Date().toISOString().split('T')[0];

      this.onOrderData.push(row);
      this.onOrder.set(this.onOrderData);
      this.deleteProduct(row);

    } else if (row.status === 'On Deck') {
      row.deliveryDate = new Date().toISOString().split('T')[0];
      
      this.onDeckData.push(row);
      this.onDeck.set(this.onDeckData);
      this.deleteProduct(row);

    } else if (row.status === 'Kicked') {
      row.kickedDate = new Date().toISOString().split('T')[0];
      
      this.onKickedData.push(row);
      this.onKicked.set(this.onKickedData);
      this.deleteProduct(row);
    }
  }

  deleteProduct(row: any) {
    let ind = this.dataSource.data.findIndex(obj => { return obj === row });

    if (ind !== -1) {
      this.dataSource.data.splice(ind, 1);
      this.table.dataSource = this.dataSource.data;
      this.onTap.set(this.dataSource.data);
    }
  }

  applyFilter(search: string) {

    this.dataSource.filter = search.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  convertToPercent($event: any) {
    return ($event) ? (parseFloat($event) * 100).toFixed(2) : '';
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;

    this.onTap.valueChanges().subscribe((value: any[]) => {
      console.log(value);
      this.dataSource.data = value;
      this.table.dataSource = this.dataSource;
      this.dataSource.paginator = this.paginator;
    })

    this.onDeck.valueChanges().subscribe((value: any[]) => {
      this.onDeckData = value;
      
    })
    this.onOrder.valueChanges().subscribe((value: any[]) => {
      this.onOrderData = value;
    })

    this.onKicked.valueChanges().subscribe((value: any[]) => {
      this.onKickedData = value;
    })
  }
}


@Component({
  selector: 'update-tap-component',
  templateUrl: 'update-tap.component.html',
})
export class UpdateTapComponent {
  constructor(public dialogRef: MatDialogRef<UpdateQuantityDialog>, @Inject(MAT_DIALOG_DATA) public data: any) { }
}
