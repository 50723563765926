import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { AngularFireDatabase, AngularFireObject } from '@angular/fire/compat/database';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { BulkDeleteDialog, BulkUpdateDialog, UpdateOrderDialog } from '../on-order/on-order.component';
import { KickedDataSource, KickedItem } from './kicked-datasource';

@Component({
  selector: 'app-kicked',
  templateUrl: './kicked.component.html',
  styleUrls: ['./kicked.component.scss']
})
export class KickedComponent implements AfterViewInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatTable) table!: MatTable<any>;
  dataSource: MatTableDataSource<KickedDataSource>;
  onOrder: AngularFireObject<any>;
  onTap: AngularFireObject<any>;
  onDeck: AngularFireObject<any>;
  onKicked: AngularFireObject<any>;
  selection = new SelectionModel<any>(true, []);
  onOrderData: any = [];
  onDeckData: any = [];
  onTappedData: any = [];
  search: string ='';

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['select', 'status', 'kickedDate', 'tapDate', 'brewery', 'name', 'style', 'abv',
   'tastingNotes', 'type', 'format', 'cost', 'pour', 'unitCost', 'pourPrice', 'pourCost', 'marginDollar', 'marginPercent',
   'tasterPrice', 'tasterCost','tasterMarginDollar', 'tasterMarginPercent', 'kickedDays'
  ];
  tempSelectionValue: any;
  bulkStatus: any;

  constructor(private db: AngularFireDatabase, public dialog: MatDialog) {
    this.dataSource = new MatTableDataSource();

    this.onOrder = db.object('On Order');
    this.onTap = db.object('On Tap');
    this.onDeck = db.object('On Deck');
    this.onKicked = db.object('Kicked');
  }

  toFixed2($event: any) {
    return ($event) ? Number($event).toFixed(2) : '';
  }

  applyFilter(search: string) {

    this.dataSource.filter = search.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

        /** Whether the number of selected elements matches the total number of rows. */
        isAllSelected() {
          const numSelected = this.selection.selected.length;
          const numRows = this.dataSource.data.length;
          return numSelected === numRows;
        }
      
        /** Selects all rows if they are not all selected; otherwise clear selection. */
        toggleAllRows() {
          if (this.isAllSelected()) {
            this.selection.clear();
            return;
          }
      
          this.selection.select(...this.dataSource.data);
        }
      
        /** The label for the checkbox on the passed row */
        checkboxLabel(row?: any): string {
          if (!row) {
            return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
          }
          return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
        }

  convertToPercent($event: any) {
    return ($event) ? (parseFloat($event) * 100).toFixed(2) : '';
  }

  bulkDelete() {
    const dialogRef = this.dialog.open(BulkDeleteDialog, {
      width: '500px',
      data: {

      }
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result) {
        this.selection.selected.forEach((selected: any) => {
          this.deleteProduct(selected);
        })
      }
    });

  }

  updateStatus(row: any, $index: any) {

    if (row.status !== this.tempSelectionValue) {
      const dialogRef = this.dialog.open(UpdateOrderDialog, {
        width: '500px',
        data: {
          row: row
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.updateProduct(row);
        } else {
          row.status = this.tempSelectionValue;
        }
      });
    }
  }

  bulkUpdateStatus(status: any) {

    if (this.bulkStatus) {
      const dialogRef = this.dialog.open(BulkUpdateDialog, {
        width: '500px',
        data: {
          status: status
        }
      });

      dialogRef.afterClosed().subscribe(result => {

        if (result) {
          this.selection.selected.forEach((selected: any, i) => {
            selected.status = this.bulkStatus;
            this.updateProduct(selected);

            if(i == this.selection.selected.length - 1) {
              this.bulkStatus = '';
            }
            
          })
        }
      });
    }
  }

  updateProduct(row: any) {
    /** Add to other status table */
    if (row.status === 'On Order') {
      row.orderDate = new Date().toISOString().split('T')[0];

      this.onOrderData.push(row);
      this.onOrder.set(this.onOrderData);
      this.deleteProduct(row);

    } else if (row.status === 'On Deck') {
      row.deliveryDate = new Date().toISOString().split('T')[0];
      
      this.onDeckData.push(row);
      this.onDeck.set(this.onDeckData);
      this.deleteProduct(row);

    } else if (row.status === 'on Tap') {
      row.tapDate = new Date().toISOString().split('T')[0];
      
      this.onTappedData.push(row);
      this.onTap.set(this.onTappedData);
      this.deleteProduct(row);
    }
  }

  deleteProduct(row: any) {
    let ind = this.dataSource.data.findIndex(obj => { return obj === row });

    if (ind !== -1) {
      this.dataSource.data.splice(ind, 1);
      this.table.dataSource = this.dataSource.data;
      this.onKicked.set(this.dataSource.data);
    }
  }

  updateCacheStatus(row: any) {
    this.tempSelectionValue = row.status;
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;

    this.onKicked.valueChanges().subscribe((value: any[]) => {
      this.dataSource.data = value;
      this.table.dataSource = this.dataSource;
      this.dataSource.paginator = this.paginator;
    })

    this.onDeck.valueChanges().subscribe((value: any[]) => {
      this.onDeckData = value;      
    })
    this.onOrder.valueChanges().subscribe((value: any[]) => {
      this.onOrderData = value;
    })

    this.onTap.valueChanges().subscribe((value: any[]) => {
      this.onTappedData = value;
    })
  }
}
