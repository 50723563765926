import { AfterViewInit, Component, Inject, ViewChild } from '@angular/core';
import { AngularFireDatabase, AngularFireObject } from '@angular/fire/compat/database';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, MatSortable } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { OnOrderDataSource, OnOrderItem } from './on-order-datasource';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import { debounceTime, startWith, switchMap } from 'rxjs/operators';
import { of } from 'rxjs/internal/observable/of';
import { SelectionModel } from '@angular/cdk/collections';

@Component({
  selector: 'app-on-order',
  templateUrl: './on-order.component.html',
  styleUrls: ['./on-order.component.scss']
})
export class OnOrderComponent implements AfterViewInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatTable) table!: MatTable<any>;
  dataSource: MatTableDataSource<OnOrderDataSource>;
  onOrder: AngularFireObject<any>;
  onTap: AngularFireObject<any>;
  onDeck: AngularFireObject<any>;
  onKicked: AngularFireObject<any>;
  productsObservable: AngularFireObject<any>;
  products: any;
  selection = new SelectionModel<any>(true, []);
  search: string = '';
  refTable = [
    { name: "sixtel", value: 595, kegCount: 1 },
    { name: "half", value: 1786 , kegCount: 3 },
    { name: "quarter", value: 893, kegCount: 2 },
    { name: "30l", value: 913, kegCount: 1 },
    { name: "50l", value: 1521, kegCount: 3 },
    { name: "24case", value: 24, kegCount: 0 },
    { name: "18case", value: 18, kegCount: 0 },
    { name: "12case", value: 12, kegCount: 0 },
    { name: "6case", value: 6, kegCount: 0 }];


  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['select', 'status', 'orderDate', 'invoiced',  'qty', 'brewery', 'name', 'style', 'abv',
    'tastingNotes', 'category', 'format',
    'cost', 'pour', 'unitCost', 'pourPrice', 'pourCost', 'marginDollar', 'marginPercent',
    'tasterCost', 'tasterPrice', 'tasterMarginDollar', 'tasterMarginPercent', 'kegCount'];
  tempSelectionValue: any;
  tempCacheQuantity: any;
  cacheInvoicedDate: any;
  bulkStatus: any;
  onDeckData: any = [];
  onTapData: any = [];
  onKickedData: any = [];

  constructor(private db: AngularFireDatabase, public dialog: MatDialog) {
    this.dataSource = new MatTableDataSource();
    this.onOrder = db.object('On Order');
    this.onTap = db.object('On Tap');
    this.onDeck = db.object('On Deck');
    this.onKicked = db.object('Kicked');
    this.productsObservable = db.object('master');
  }

  sortData($event: any) {

  }

  bulkUpdateStatus(status: any) {

    if (status) {
      const dialogRef = this.dialog.open(BulkUpdateDialog, {
        width: '500px',
        data: {
          status: status
        }
      });

      dialogRef.afterClosed().subscribe(result => {

        if (result) {
          this.selection.selected.forEach((selected: any, i) => {
            selected.status = status;
            this.updateProduct(selected);
          })
        }
      });
    }
  }

  bulkDelete() {
    const dialogRef = this.dialog.open(BulkDeleteDialog, {
      width: '500px',
      data: {

      }
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result) {
        this.selection.selected.forEach((selected: any) => {
          this.deleteProduct(selected);
        })
      }
    });

  }

  toFixed2($event: any) {
    return ($event) ? Number($event).toFixed(2) : '';
  }

  updateRecieved() {
    this.onOrder.set(this.dataSource.data);
  }

  updateInvoicedDate(row: any) {

    if (this.cacheInvoicedDate != row.invoiced) {
      const dialogRef = this.dialog.open(UpdateInvoicedDate, {
        width: '500px',
        data: {
          row: row
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.onOrder.set(this.dataSource.data);
        } else {
          row.invoiced = this.cacheInvoicedDate;
        }
      });
    }
  }

  addAProduct() {
    const dialogRef = this.dialog.open(AddAProduct, {
      width: '800px',
      data: {
        products: this.products
      }
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result) {
        console.log(result);
        result.forEach((product: any) => {
          product.abv = product.abv;
          product.cost = product.cost;
          product.marginDollar = product.marginDollar;
          product.marginPercent = product.marginPercent;
          product.pourCost = product.pourCost;
          product.pourPrice = product.pourPrice;
          product.tasterCost = product.tasterCost;
          product.tasterPrice = product.tasterPrice;
          product.unitCost = product.unitCost;
          product.orderDate = new Date().toISOString().split('T')[0];
          product.invoiced = "null";

          if (!product.qty) {
            product.qty = 1; //editable
          }

          product.kegCount = this.refTable.filter(format=> format.name === product.format.toLowerCase())[0].kegCount;
          product.status = "On Order";
          product.category = product.lightAleLager; // coming from master table
          product.kegCount = (!product.kegCount)? 0: product.kegCount*parseInt(product.qty);  // qty x format 
          product.madeLabel = "No"; // field will go away , take out madeLabel from everywhere
          product.tasterMarginDollar = product.tasterMarginDollar; // should come from products maaster table
          product.tasterMarginPercent = product.tasterMarginPercent; // should come from products maaster table
          product.received = "null";
          product.size = "170z"; // come from master products table

          this.dataSource.data.push(product);
        });

        this.onOrder.set(this.dataSource.data);
      } else {

      }
    });

  }

  updateCacheInvoicedDate(row: any) {
    this.cacheInvoicedDate = row.invoiced;
  }

  updateQuantity(row: any) {

    if (row.qty !== this.tempCacheQuantity) {
      const dialogRef = this.dialog.open(UpdateQuantityDialog, {
        width: '500px',
        data: {
          row: row
        }
      });

      dialogRef.afterClosed().subscribe(result => {

        if (result) {
          this.onOrder.set(this.dataSource.data);
        } else {
          row.qty = this.tempCacheQuantity;
        }
      });
    }
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  updateCacheQuantity(row: any) {
    this.tempCacheQuantity = row.qty;
  }

  updateCacheStatus(row: any) {
    this.tempSelectionValue = row.status;
  }

  updateProduct(row: any) {
    /** Add to other status table */
    if (row.status === 'On Order') {
      row.orderDate = new Date().toISOString().split('T')[0];
      this.onOrder.set(this.dataSource.data);

    } else if (row.status === 'On Deck') {
      row.deliveryDate = new Date().toISOString().split('T')[0];
      this.onDeckData.push(row);
      this.onDeck.set(this.onDeckData);
      this.deleteProduct(row);

    } else if (row.status === 'On Tap') {
      row.tapDate = new Date().toISOString().split('T')[0];
      this.onTapData.push(row);
      this.onTap.set(this.onTapData);
      this.deleteProduct(row);

    } else if (row.status === 'Kicked') {
      row.kickedDate = new Date().toISOString().split('T')[0];
      this.onKickedData.push(row);
      this.onKicked.set(this.onKickedData);
      this.deleteProduct(row);
    }
  }

  updateStatus(row: any, $index: any) {

    if (row.status !== this.tempSelectionValue) {
      const dialogRef = this.dialog.open(UpdateOrderDialog, {
        width: '500px',
        data: {
          row: row
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.updateProduct(row);
        } else {
          row.status = this.tempSelectionValue;
        }
      });
    }
  }

  deleteProduct(row: any) {
    let ind = this.dataSource.data.findIndex(obj => { return obj === row });

    if (ind !== -1) {
      this.dataSource.data.splice(ind, 1);
      this.table.dataSource = this.dataSource.data;
      this.onOrder.set(this.dataSource.data);
    }
  }

  deleteRow(row: any) {
    const dialogRef = this.dialog.open(DeleteOrderDialog, {
      width: '250px',
      data: {
        row: row
      }
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result) {
        this.deleteProduct(row);
      }
    });
  }

  calculateKegCount(product: any) {
    product.kegCount = this.refTable.filter(format=> format.name === product.format.toLowerCase())[0].kegCount;
    product.kegCount = (!product.kegCount)? 0: product.kegCount*parseInt(product.qty); 
    return product.kegCount;
  }

  convertToPercent($event: any) {
    return ($event) ? (parseFloat($event) * 100).toFixed(2) : '';
  }

  convertToPercentABV($event: any) {
    return ($event) ? (parseFloat($event)*100 / 100).toFixed(2) : '';
  }

  applyFilter(search: string) {

    this.dataSource.filter = search.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;

    this.onOrder.valueChanges().subscribe((value: any[]) => {
      this.dataSource.data = value;
      console.log(value);
      this.table.dataSource = this.dataSource;
      this.dataSource.paginator = this.paginator;
    })
    this.onTap.valueChanges().subscribe((value: any[]) => {
      this.onTapData = value;

    })
    this.onDeck.valueChanges().subscribe((value: any[]) => {
      this.onDeckData = value;
    })

    this.onKicked.valueChanges().subscribe((value: any[]) => {
      this.onKickedData = value;
    })

    this.productsObservable.valueChanges().subscribe((value: any[]) => {
      this.products = value;
    })
  }
}

@Component({
  selector: 'delete-order-dialog',
  templateUrl: 'delete-order-dialog.html',
})
export class DeleteOrderDialog {
  constructor(public dialogRef: MatDialogRef<DeleteOrderDialog>, @Inject(MAT_DIALOG_DATA) public data: any) { }
}

@Component({
  selector: 'update-status-dialog',
  templateUrl: 'update-status-dialog.html',
})
export class UpdateOrderDialog {
  constructor(public dialogRef: MatDialogRef<UpdateOrderDialog>, @Inject(MAT_DIALOG_DATA) public data: any) { }
}


@Component({
  selector: 'update-quantity-dialog',
  templateUrl: 'update-quantity-dialog.html',
})
export class UpdateQuantityDialog {
  constructor(public dialogRef: MatDialogRef<UpdateQuantityDialog>, @Inject(MAT_DIALOG_DATA) public data: any) { }
}

@Component({
  selector: 'bulk-update-dialog',
  templateUrl: 'bulk-update-dialog.html',
})
export class BulkUpdateDialog {
  constructor(public dialogRef: MatDialogRef<BulkUpdateDialog>, @Inject(MAT_DIALOG_DATA) public data: any) { }
}

@Component({
  selector: 'bulk-delete-dialog',
  templateUrl: 'bulk-delete-dialog.html',
})
export class BulkDeleteDialog {
  constructor(public dialogRef: MatDialogRef<BulkDeleteDialog>, @Inject(MAT_DIALOG_DATA) public data: any) { }
}

@Component({
  selector: 'update-invoiced-date-dialog',
  templateUrl: 'update-invoiced-date.html',
})
export class UpdateInvoicedDate {
  constructor(public dialogRef: MatDialogRef<UpdateInvoicedDate>, @Inject(MAT_DIALOG_DATA) public data: any) { }
}

@Component({
  selector: 'add-a-product-dialog',
  templateUrl: 'add-a-product.html',
  styleUrls: ['./on-order.component.scss']
})
export class AddAProduct {
  selectedProducts: string[] = [];
  search = new FormControl();
  constructor(public dialogRef: MatDialogRef<AddAProduct>, @Inject(MAT_DIALOG_DATA) public data: any) {

    this.selectedProducts = [];
  }

  public onSelectedOptionsChange() {
    setTimeout(() => {
      this.selectedProducts = this.data.products.filter((product: any) => {
        return product.selected;
      });
    })
  }

  $search = this.search.valueChanges.pipe(
    startWith(null),
    debounceTime(200),
    switchMap((res: string) => {
      if (!res) return of(this.data.products);
      res = res.toLowerCase();
      return of(
        this.data.products.filter((x: any) => x.name.toLowerCase().indexOf(res) >= 0)
      );
    })
  );
}
