
<h3 mat-dialog-title> Add A Product (Wine) </h3>

<div mat-dialog-content>
<form [formGroup]="wineForm" (ngSubmit)="onSubmit()">
    
    <mat-form-field appearance="outline">
        <mat-label for="first-name">Order Date: </mat-label>
        <input matInput id="first-name" type="text" formControlName="orderDate">
    </mat-form-field>

    <mat-form-field appearance="outline">
        <mat-label for="last-name">Name Style: </mat-label>
        <input matInput id="last-name" type="text" formControlName="nameStyle">
    </mat-form-field>

    <mat-form-field appearance="outline">
        <mat-label for="last-name">Invoiced: </mat-label>
        <input matInput id="last-name" type="text" formControlName="invoiced">

    </mat-form-field>
    <mat-form-field appearance="outline">
        <mat-label for="last-name">Winery: </mat-label>
        <input matInput id="last-name" type="text" formControlName="winery">
    </mat-form-field>
    <mat-form-field appearance="outline">
        <mat-label for="last-name">Quantity Case: </mat-label>
        <input matInput id="last-name" type="text" (change)="updateBottleCost()" formControlName="qtyCase">

    </mat-form-field>
    <mat-form-field appearance="outline">
        <mat-label for="last-name">Received: </mat-label>
        <input matInput id="last-name" type="number" formControlName="received">

    </mat-form-field>
    <mat-form-field appearance="outline">
        <mat-label for="last-name">Quantity: </mat-label>
        <input matInput id="last-name" type="text" formControlName="qty">
    </mat-form-field>

    <mat-form-field appearance="outline">
        <mat-label for="last-name">Bottle Cost: </mat-label>
        <span matPrefix>&nbsp; $ &nbsp;</span>
        <input matInput id="last-name" type="number" [readonly]="true" formControlName="bottleCost">
    </mat-form-field>

    <mat-form-field appearance="outline">
        <mat-label for="last-name">Case Cost: </mat-label>
        <span matPrefix>&nbsp; $ &nbsp;</span>
        <input matInput id="last-name" (change)="updateBottleCost()" type="text" formControlName="caseCost">
    </mat-form-field>

    <mat-form-field appearance="outline">
        <mat-label for="last-name">Cost Pour: </mat-label>
        <span matPrefix>&nbsp; $ &nbsp;</span>
        <input matInput id="last-name" type="text" (change)="updateMarginDollar()" formControlName="costPour">
    </mat-form-field>

    <mat-form-field appearance="outline">
        <mat-label for="last-name">Price Pour: </mat-label>
        <span matPrefix>&nbsp; $ &nbsp;</span>
        <input matInput id="last-name" type="text" (change)="updateMarginDollar()" formControlName="pricePour">
    </mat-form-field>

    <mat-form-field appearance="outline">
        <mat-label for="last-name">Flight Cost: </mat-label>
        <span matPrefix>&nbsp; $ &nbsp;</span>
        <input matInput id="last-name"  type="number" formControlName="flightCost">
    </mat-form-field>

    <mat-form-field appearance="outline">
        <mat-label for="last-name">Flight Price: </mat-label>
        <span matPrefix>&nbsp; $ &nbsp;</span>
        <input matInput id="last-name" (change)="updateMarginFlightDollar()"  type="text" formControlName="flightPrice">
    </mat-form-field>

    <mat-form-field appearance="outline">
        <mat-label for="last-name">Flight Margin Dollar: </mat-label>
        <span matPrefix>&nbsp; $ &nbsp;</span>
        <input matInput id="last-name"  type="text" [readonly]="true" formControlName="flightMarginDollar">
    </mat-form-field>

    <mat-form-field appearance="outline">
        <mat-label for="last-name">Flight Margin Percent: </mat-label>
        <input matInput id="last-name"  type="text" [readonly]="true" formControlName="flightMarginPercent">
        <span matSuffix>&nbsp; % &nbsp;</span>
    </mat-form-field>

    <mat-form-field appearance="outline">
        <mat-label for="last-name">Bottle Price: </mat-label>
        <span matPrefix>&nbsp; $ &nbsp;</span>
        <input matInput id="last-name"  type="text"  (change)="updateMarginBottleDollar()"  formControlName="bottlePrice">
    </mat-form-field>

    <mat-form-field appearance="outline">
        <mat-label for="last-name">Bottle Margin Dollar: </mat-label>
        <span matPrefix>&nbsp; $ &nbsp;</span>
        <input matInput id="last-name"  type="text" [readonly]="true" formControlName="bottleMarginDollar">
    </mat-form-field>

    <mat-form-field appearance="outline">
        <mat-label for="last-name">Margin Dollar: </mat-label>
        <span matPrefix>&nbsp; $ &nbsp;</span>
        <input matInput id="last-name"  type="text" [readonly]="true" formControlName="marginDollar">
    </mat-form-field>

    <mat-form-field appearance="outline">
        <mat-label for="last-name">Margin Percent: </mat-label>
        <input matInput id="last-name"  type="text" [readonly]="true" formControlName="marginPercent">
        <span matSuffix>&nbsp; % &nbsp;</span>
    </mat-form-field>

    <mat-form-field appearance="outline">
        <mat-label for="last-name">Bottle Margin Percent: </mat-label>
        <input matInput id="last-name"  type="text" [readonly]="true" formControlName="bottleMarginPercent">
        <span matSuffix>&nbsp; % &nbsp;</span>
    </mat-form-field>

    <button type="submit" color="primary" mat-raised-button [disabled]="!wineForm.valid">Submit</button>
</form>
</div>