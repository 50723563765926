
  <mat-form-field class="search-product">
    <mat-label>Search</mat-label>
    <input matInput (keyup)="applyFilter(search)" [(ngModel)]="search"
      placeholder="Search by any column. Eg: cost, margin, date" #input>
    <button *ngIf="search" matSuffix mat-icon-button aria-label="Clear" (click)="search=''; applyFilter(search)">
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>

<button class="group-button" mat-button [matMenuTriggerFor]="menu">Actions</button>
<mat-menu #menu="matMenu">
  <button mat-menu-item (click)="bulkDelete()">delete <mat-icon>delete</mat-icon></button>
  <button mat-menu-item [matMenuTriggerFor]="bulkUpdate">Bulk Update</button>
</mat-menu>

<mat-menu #bulkUpdate="matMenu">
  <button mat-menu-item (click)="bulkUpdateStatus('On Order')">On Order</button>
  <button mat-menu-item (click)="bulkUpdateStatus('Kicked')">Kicked</button>
  <button mat-menu-item (click)="bulkUpdateStatus('On Deck')">On Deck</button>
  <button mat-menu-item (click)="bulkUpdateStatus('On Tap')">On Tap</button>
</mat-menu>

<div class="mat-elevation-z8">
  <table mat-table class="table-responsive" matSort aria-label="Elements">

       <!-- Checkbox Column -->
  <ng-container matColumnDef="select">
    <th mat-header-cell *matHeaderCellDef>
      <mat-checkbox (change)="$event ? toggleAllRows() : null"
                    [checked]="selection.hasValue() && isAllSelected()"
                    [indeterminate]="selection.hasValue() && !isAllSelected()"
                    [aria-label]="checkboxLabel()">
      </mat-checkbox>
    </th>
    <td mat-cell *matCellDef="let row">
      <mat-checkbox (click)="$event.stopPropagation()"
                    (change)="$event ? selection.toggle(row) : null"
                    [checked]="selection.isSelected(row)"
                    [aria-label]="checkboxLabel(row)">
      </mat-checkbox>
    </td>
  </ng-container>

    <!-- Id Column -->
    <ng-container matColumnDef="abv">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Abv</th>
      <td mat-cell *matCellDef="let row">{{row.abv}}%</td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
      <td mat-cell *matCellDef="let row">{{row.name}}</td>
    </ng-container>

    <!-- Quantity Column -->
    <ng-container matColumnDef="qty">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Quantity</th>
      <td mat-cell *matCellDef="let row">
        <mat-form-field class="quantity-form-field">
          <input matInput type="text" (click)="updateCacheQuantity(row)" (blur)="updateQuantity(row)"
            [(ngModel)]="row.qty">
        </mat-form-field>
      </td>
    </ng-container>

    <!-- Brewery Column -->
    <ng-container matColumnDef="brewery">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Brewery</th>
      <td mat-cell *matCellDef="let row">{{row.brewery}}</td>
    </ng-container>

    <!-- Category Column -->
    <ng-container matColumnDef="category">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Category</th>
      <td mat-cell *matCellDef="let row">{{row.category}}</td>
    </ng-container>

    <!-- Style Column -->
    <ng-container matColumnDef="style">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Style</th>
      <td mat-cell *matCellDef="let row">{{row.style}}</td>
    </ng-container>

    <!-- Cost Column -->
    <ng-container matColumnDef="cost">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Cost</th>
      <td mat-cell *matCellDef="let row">${{row.cost}}</td>
    </ng-container>

    <!-- Format Column -->
    <ng-container matColumnDef="format">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Format</th>
      <td mat-cell *matCellDef="let row">{{row.format}}</td>
    </ng-container>

    <!-- KegCount Column -->
    <ng-container matColumnDef="kegCount">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Keg Count</th>
      <td mat-cell *matCellDef="let row">{{calculateKegCount(row)}}</td>
    </ng-container>

    <!-- Invoiced Column -->
    <ng-container matColumnDef="invoiced">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Invoiced Date</th>
      <td mat-cell *matCellDef="let row">
        <input matInput type="date" (click)="updateCacheInvoicedDate(row)" (blur)="updateInvoicedDate(row)"
          [(ngModel)]="row.invoiced">
      </td>
    </ng-container>

    <!-- Made Label Column -->
    <ng-container matColumnDef="madeLabel">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Made Label?</th>
      <td mat-cell *matCellDef="let row">{{row.madeLabel}}</td>
    </ng-container>

    <!-- Status Column -->
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
      <td mat-cell *matCellDef="let row; let i = index">
        <mat-form-field appearance="fill">
          <mat-select (opened)="updateCacheStatus(row)" (selectionChange)="updateStatus(row,i)" [(ngModel)]="row.status"
            name="status">
            <mat-option value="On Order">On Order</mat-option>
            <mat-option value="Kicked">Kicked</mat-option>
            <mat-option value="On Deck">On Deck</mat-option>
            <mat-option value="On Tap">On Tap</mat-option>
          </mat-select>
        </mat-form-field>

      </td>
    </ng-container>

    <!-- Margin Dollar Column -->
    <ng-container matColumnDef="marginDollar">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Pour Margin ($)</th>
      <td mat-cell *matCellDef="let row">${{toFixed2(row.marginDollar)}}</td>
    </ng-container>

    <!-- Margin Percent Column -->
    <ng-container matColumnDef="marginPercent">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Pour Margin (%)</th>
      <td mat-cell *matCellDef="let row">{{convertToPercent(row.marginPercent)}}%</td>
    </ng-container>

    <!-- Taster Cost Column -->
    <ng-container matColumnDef="tasterCost">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Taster Cost</th>
      <td mat-cell *matCellDef="let row">${{toFixed2(row.tasterCost)}}</td>
    </ng-container>

    <!-- Taster Price Column -->
    <ng-container matColumnDef="tasterPrice">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Taster Price</th>
      <td mat-cell *matCellDef="let row"><b>${{toFixed2(row.tasterPrice)}}</b></td>
    </ng-container>

    <!-- Margin Dollar Column -->
    <ng-container matColumnDef="tasterMarginDollar">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Taster Margin ($)</th>
      <td mat-cell *matCellDef="let row">${{toFixed2(row.tasterMarginDollar)}}</td>
    </ng-container>

    <!-- Margin Percent Column -->
    <ng-container matColumnDef="tasterMarginPercent">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Taster Margin(%)</th>
      <td mat-cell *matCellDef="let row">{{convertToPercent(row.tasterMarginPercent)}}%</td>
    </ng-container>

    <!-- Order Date Column -->
    <ng-container matColumnDef="orderDate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Order Date</th>
      <td mat-cell *matCellDef="let row"><b> {{row.orderDate | date: 'MMM d, y'}} </b></td> <!-- | date: 'MMM d, y' -->
    </ng-container>

    <!-- Pour Column -->
    <ng-container matColumnDef="pour">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Pour</th>
      <td mat-cell *matCellDef="let row"><b>{{row.pour}}</b></td>
    </ng-container>

        <!-- Tasting Note Column -->
        <ng-container matColumnDef="tastingNote">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Tasting Notes</th>
          <td mat-cell *matCellDef="let row">{{row.tastingNotes}}</td>
        </ng-container>

    <!-- Pour Cost Column -->
    <ng-container matColumnDef="pourCost">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Pour Cost</th>
      <td mat-cell *matCellDef="let row">${{toFixed2(row.pourCost)}}</td>
    </ng-container>

    <!-- Pour Price Column -->
    <ng-container matColumnDef="pourPrice">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Pour Price</th>
      <td mat-cell *matCellDef="let row"><b>${{row.pourPrice}}</b></td>
    </ng-container>

    <ng-container matColumnDef="received">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Received</th>
      <td mat-cell *matCellDef="let row">
        <mat-slide-toggle (change)="updateRecieved()" [(ngModel)]="row.received"></mat-slide-toggle>
      </td>
    </ng-container>


    <!-- Unit Cost Column -->
    <ng-container matColumnDef="unitCost">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Unit Cost</th>
      <td mat-cell *matCellDef="let row">${{toFixed2(row.unitCost)}}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <mat-paginator #paginator [length]="dataSource.data.length" [pageIndex]="0" [pageSize]="100"
    [pageSizeOptions]="[5, 10, 20]" aria-label="Select page">
  </mat-paginator>
</div>