<h1 mat-dialog-title>Add product to master </h1>
<div mat-dialog-content>
<form [formGroup]="masterForm" (ngSubmit)="onSubmit()">
        <mat-form-field appearance="outline">
        <mat-label for="last-name">Brewery: </mat-label>
        <input matInput id="last-name" type="text" formControlName="brewery">
    </mat-form-field>

        <mat-form-field appearance="outline">
        <mat-label for="last-name">Name: </mat-label>
        <input matInput id="last-name" type="text" formControlName="name">
    </mat-form-field>

        <mat-form-field appearance="outline">
        <mat-label for="last-name">Style: </mat-label>
        <input matInput id="last-name" type="text" formControlName="style">
    </mat-form-field>

    <mat-form-field appearance="outline">
        <mat-label for="first-name">Abv: </mat-label>
        <input matInput id="first-name" type="text" formControlName="abv">
    </mat-form-field>

    <mat-form-field appearance="outline">
        <mat-label for="last-name">Tasting Notes: </mat-label>
        <input matInput id="last-name" type="text" formControlName="tastingNotes">
    </mat-form-field>

    <mat-form-field appearance="outline">
        <mat-label for="last-name">Category: </mat-label>
        <mat-select formControlName="lightAleLager">
            <mat-option *ngFor="let ref of category" [value]="ref.type">{{ref.type}}</mat-option>
          </mat-select>
    </mat-form-field>

        <mat-form-field appearance="outline">
        <mat-label for="last-name">Format: </mat-label>
        <mat-select formControlName="format" (selectionChange)="updateUnitCost()">
            <mat-option *ngFor="let ref of refTable" [value]="ref.name">{{ref.name}}</mat-option>
          </mat-select>
    </mat-form-field>

        <mat-form-field appearance="outline">
        <mat-label for="last-name">Cost: </mat-label>
        <span matPrefix>&nbsp; $ &nbsp;</span>
        <input matInput id="last-name" (change)="updateUnitCost()" type="number" formControlName="cost">
    </mat-form-field>

    <mat-form-field appearance="outline">
        <mat-label for="last-name">Pour: </mat-label>
        <mat-select formControlName="pour" (selectionChange)="updatePourCost()">
            <mat-option *ngFor="let ref of pourSelection" [value]="ref.name">{{ref.name}}</mat-option>
          </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline">
        <mat-label for="last-name">Pour Price: </mat-label>
        <span matPrefix>&nbsp; $ &nbsp;</span>
        <input matInput id="last-name" type="number" formControlName="pourPrice">
    </mat-form-field>


    <mat-form-field appearance="outline">
        <mat-label for="last-name">Pour Cost: </mat-label>
        <span matPrefix>&nbsp; $ &nbsp;</span>
        <input matInput id="last-name" type="number" readonly="true" formControlName="pourCost">
    </mat-form-field>

    <mat-form-field appearance="outline">
        <mat-label for="last-name">Unit Cost: </mat-label>
        <span matPrefix>&nbsp; $ &nbsp;</span>
        <input matInput id="last-name" readonly="true" type="number" formControlName="unitCost">
    </mat-form-field>

    
    <mat-form-field appearance="outline">
        <mat-label for="last-name">Taster Price: </mat-label>
        <span matPrefix>&nbsp; $ &nbsp;</span>
        <input matInput id="last-name" type="number" (change)="updateUnitCost()" formControlName="tasterPrice">
    </mat-form-field>

    <mat-form-field appearance="outline">
        <mat-label for="last-name">Taster Cost: </mat-label>
        <span matPrefix>&nbsp; $ &nbsp;</span>
        <input matInput id="last-name" readonly="true" type="text" formControlName="tasterCost">
    </mat-form-field>

    <mat-form-field appearance="outline">
        <mat-label for="last-name">Pour Margin $: </mat-label>
        <span matPrefix>&nbsp; $ &nbsp;</span>
        <input matInput id="last-name" readonly="true" type="text" formControlName="marginDollar">
    </mat-form-field>

    <mat-form-field appearance="outline">
        <mat-label for="last-name">Pour Margin %: </mat-label>
        <input matInput id="last-name" readonly="true" type="text" formControlName="marginPercent">
        <span matSuffix>&nbsp; % &nbsp;</span>
    </mat-form-field>

    <mat-form-field appearance="outline">
        <mat-label for="last-name">Taster Margin $: </mat-label>
        <span matPrefix>&nbsp; $ &nbsp;</span>
        <input matInput id="last-name" readonly="true" type="text" formControlName="tasterMarginDollar">
    </mat-form-field>

    <mat-form-field appearance="outline">
        <mat-label for="last-name">Taster Margin %: </mat-label>
        <span matPrefix>&nbsp; $ &nbsp;</span>
        <input matInput id="last-name" readonly="true" type="text" formControlName="tasterMarginPercent">
    </mat-form-field>
    
    <button type="submit" color="primary" mat-raised-button [disabled]="!masterForm.valid">Submit</button>
</form>
</div>