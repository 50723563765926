<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer class="sidenav" fixedInViewport [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
    [mode]="(isHandset$ | async) ? 'over' : 'side'" [opened]="(isHandset$ | async) === false">
    <mat-toolbar>OOTB</mat-toolbar>
    <mat-nav-list>
      <a mat-list-item href="/on-order">On Order</a>
      <a mat-list-item href="/on-deck">On Deck</a>
      <a mat-list-item href="/on-tap">On Tap</a>
      <a mat-list-item href="/on-kicked">Kicked</a>
      <a mat-list-item href="/master">Master</a>
      <a mat-list-item href="/packaged">Packaged</a>
      <a mat-list-item href="/foodMerch">Food Merch</a>
      <a mat-list-item href="/wine">Wine</a>
      <a mat-list-item href="/summary">Summary</a>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar color="primary">
      <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="drawer.toggle()"
        *ngIf="isHandset$ | async">
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      <span>Out Of The Barrel - {{this.activePage | titlecase}}</span>
    </mat-toolbar>
    <!-- Add Content Here -->

    <router-outlet> </router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>