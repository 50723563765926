<h1 mat-dialog-title>Add A Product</h1>
<div mat-dialog-content>

  <mat-form-field class="search-product">
    <mat-label>Search</mat-label>
    <input matInput [formControl]="search">
  </mat-form-field>

  <mat-selection-list class="add-a-product" #productList (selectionChange)="onSelectedOptionsChange()">
    <mat-list-option *ngFor="let product of $search | async" (click)="product.selected = !product.selected;"
      [selected]="product.selected" [value]="product">

      <span matListItemTitle> <b> {{product.name}} </b> - <small> {{product.brewery}} ({{product.format}})  </small>  </span>
      <span matListItemLine> <mat-form-field>
          <mat-label>Add Quantity</mat-label>
          <input placeholder="Add quantity" matInput type="text" [(ngModel)]="product.qty">
        </mat-form-field>
      </span>


    </mat-list-option>
  </mat-selection-list>

</div>
<div mat-dialog-actions>
  <button color="warn" mat-button [mat-dialog-close]="selectedProducts" cdkFocusInitial>Add</button>
  <button mat-button [mat-dialog-close]="false">Cancel</button>
</div>