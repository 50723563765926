import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, Component, Inject, ViewChild } from '@angular/core';
import { AngularFireDatabase, AngularFireObject } from '@angular/fire/compat/database';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Papa } from 'ngx-papaparse';
import { ReOrderDialog } from '../food-merch/food-merch.component';
import { BulkDeleteDialog, DeleteOrderDialog, UpdateInvoicedDate, UpdateQuantityDialog } from '../on-order/on-order.component';
import { PackagedDataSource } from './packaged-datasource';

@Component({
  selector: 'app-packaged',
  templateUrl: './packaged.component.html',
  styleUrls: ['./packaged.component.scss']
})
export class PackagedComponent implements AfterViewInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatTable) table!: MatTable<any>;
  dataSource: MatTableDataSource<PackagedDataSource>;
  packaged: AngularFireObject<any>;
  selection = new SelectionModel<any>(true, []);
  refTable = [
    { name: "sixtel", value: 595, kegCount: 1 },
    { name: "half", value: 1786 , kegCount: 3 },
    { name: "quarter", value: 893, kegCount: 2 },
    { name: "30l", value: 913, kegCount: 1 },
    { name: "50l", value: 1521, kegCount: 3 },
    { name: "24case", value: 24, kegCount: 0 },
    { name: "18case", value: 18, kegCount: 0 },
    { name: "12case", value: 12, kegCount: 0 },
    { name: "6case", value: 6, kegCount: 0 }];

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['select', 'reorder', 'orderDate', 'invoiced', 'received', 'qty', 'brewery', 'name', 'style', 'format', 'caseCost', 'pour', 'unitCost', 'unitPrice', 'marginDollar', 'marginPercent', 'packCount', 'packCost', 'packPrice', 'packMarginDollar', 'packMarginPercent'];
  tempCacheQuantity: any;
  cacheInvoicedDate: any;
  search: string ='';

  constructor(private db: AngularFireDatabase, public dialog: MatDialog, public papa: Papa) {
    this.dataSource = new MatTableDataSource();
    this.packaged = db.object('packaged');

  }

  updateCacheQuantity(row: any) {
    this.tempCacheQuantity = row.qty;
  }

  reorderProduct(row: any) {
    const dialogRef = this.dialog.open(ReOrderDialog, {
      width: '500px',
      data: {
        row: row
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        let newProduct = Object.assign({}, row);
        newProduct.orderDate = new Date().toLocaleDateString();
        newProduct.invoiced = null;
        this.dataSource.data.push(newProduct);
        this.packaged.set(this.dataSource.data);

      }
    });
  }

  updateInvoicedDate(row: any) {

    if (this.cacheInvoicedDate != row.invoiced) {
      const dialogRef = this.dialog.open(UpdateInvoicedDate, {
        width: '500px',
        data: {
          row: row
        }
      });

      dialogRef.afterClosed().subscribe(result => {

        if (result) {
          this.packaged.set(this.dataSource.data);
        } else {
          row.invoiced = this.cacheInvoicedDate;
        }
      });
    }
  }

  updateCacheInvoicedDate(row: any) {
    this.cacheInvoicedDate = row.invoiced;
  }

  updateRecieved(row: any) {
    this.packaged.set(this.dataSource.data);
  }
  toFixed2($event: any) {
    return ($event) ? parseFloat($event).toFixed(2) : '';
  }
  updateQuantity(row: any) {
    if (row.qty !== this.tempCacheQuantity) {
      const dialogRef = this.dialog.open(UpdateQuantityDialog, {
        width: '500px',
        data: {
          row: row
        }
      });

      dialogRef.afterClosed().subscribe(result => {

        if (result) {
          this.packaged.set(this.dataSource.data);
        } else {
          row.qty = this.tempCacheQuantity;
        }
      });
    }
  }

  public changeListener(event: any) {

    let files = event.target.files;

    if (files && files.length > 0) {
      let file: any = files.item(0);
      let reader: FileReader = new FileReader();
      reader.readAsText(file);
      reader.onload = (e) => {
        let csv: string = reader.result as string;

        this.papa.parse(csv, {
          header: true,
          complete: (result: any) => {
            this.dataSource.data = this.dataSource.data.concat(result.data);
            this.packaged.set(this.dataSource.data);

            result.data.forEach((row: any, i: any) => {

              if (row.format && row.caseCost) {
                let selectedFormat = this.refTable.filter(format => format.name === row.format)[0]
                if (selectedFormat) {
                  let cost: any = row.caseCost.split('$')[1];
                  let unitCost: any = (cost / selectedFormat.value).toFixed(2);
                  row.unitCost = unitCost;
                }

              } else {
                row.unitCost = 0;
              }

              if (row.unitPrice && row.unitCost) {
                row.marginDollar = (row.unitPrice.split('$')[1] - row.unitCost).toFixed(2);

                if (row.marginDollar && row.unitPrice) {
                  row.marginPercent = (row.marginDollar / row.unitPrice.split('$')[1]);
                }
              }

              if (row.packCount && row.unitCost) {
                row.packCost = (row.unitCost.value * row.packCount);

                if (!row.packCost) {
                  row.packCost = 0;
                }

                if (row.unitPrice && row.unitCost) {
                  row.packMarginDollar = (row.unitPrice.split('$')[1] - row.unitCost).toFixed(2);

                  if (row.packMarginDollar && row.packPrice) {
                    row.packMarginPercent = (row.packMarginDollar / row.packPrice.split('$')[1])

                    if (row.packMarginPercent === Infinity) {
                      row.packMarginPercent = 0;
                    }
                  }
                }
              }


              if (i === result.data.length - 1) {
                this.dataSource.data = this.dataSource.data.concat(result.data);
                this.packaged.set(this.dataSource.data);
              }
            });
          }
        });

      }
    }
  }

  deleteRow(row: any) {
    const dialogRef = this.dialog.open(DeleteOrderDialog, {
      width: '250px',
      data: {
        row: row
      }
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result) {
        this.deleteProduct(row);
      }
    });
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  addAProductToPackaged() {
    const dialogRef = this.dialog.open(AddAProductToPackaged);

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.dataSource.data.push(result);
        this.packaged.set(this.dataSource.data);
      }
    });
  }


  bulkDelete() {
    const dialogRef = this.dialog.open(BulkDeleteDialog, {
      width: '500px',
      data: {
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.selection.selected.forEach((selected: any) => {
          this.deleteProduct(selected);
        })
      }
    });
  }

  deleteProduct(row: any) {
    let ind = this.dataSource.data.findIndex(obj => { return obj === row });

    if (ind !== -1) {
      this.dataSource.data.splice(ind, 1);
      this.table.dataSource = this.dataSource.data;
      this.packaged.set(this.dataSource.data);
    }
  }


  applyFilter(search: string) {

    this.dataSource.filter = search.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;

    this.packaged.valueChanges().subscribe((value: any[]) => {
      this.dataSource.data = value;
      this.table.dataSource = this.dataSource;
      this.dataSource.paginator = this.paginator;
    })

  }
}

@Component({
  selector: 'add-product-to-packaged',
  templateUrl: 'add-a-product-to-packaged.html',
  styleUrls: ['./add-a-product-packaged.component.scss']
})

export class AddAProductToPackaged {
  packagedForm = new FormGroup({
    orderDate: new FormControl('', [Validators.required]),
    name: new FormControl('', [Validators.required]),
    received: new FormControl('false', [Validators.required]),
    invoiced: new FormControl('', []),
    qty: new FormControl<number>(0, [Validators.required]),
    brewery: new FormControl('', [Validators.required]),
    style: new FormControl('', [Validators.required]),
    format: new FormControl('', [Validators.required]),
    caseCost: new FormControl<number>(0, [Validators.required]),
    pour: new FormControl('Bottle/Can', [Validators.required]),
    unitCost: new FormControl<number>(0, [Validators.required]),
    unitPrice: new FormControl<number>(0, [Validators.required]),
    marginDollar: new FormControl<number>(0, [Validators.required]),
    marginPercent: new FormControl<number>(0, [Validators.required]),
    packCount: new FormControl<number>(0, []),
    packCost: new FormControl<number>(0, [Validators.required]),
    packPrice: new FormControl<number>(0, [Validators.required]),
    packMarginDollar: new FormControl<number>(0, [Validators.required]),
    packMarginPercent: new FormControl<number>(0, [Validators.required])
  });

  refTable = [
    { name: "sixtel", value: 595, kegCount: 1 },
    { name: "half", value: 1786 , kegCount: 3 },
    { name: "quarter", value: 893, kegCount: 2 },
    { name: "30l", value: 913, kegCount: 1 },
    { name: "50l", value: 1521, kegCount: 3 },
    { name: "24case", value: 24, kegCount: 0 },
    { name: "18case", value: 18, kegCount: 0 },
    { name: "12case", value: 12, kegCount: 0 },
    { name: "6case", value: 6, kegCount: 0 }];

  pourSelection = [
    { name: "Bottle/Can", value: 1 }
  ]

  constructor(public dialogRef: MatDialogRef<AddAProductToPackaged>, @Inject(MAT_DIALOG_DATA) public data: any) {

  }

  updateUnitCost() {
    if (this.packagedForm.controls.format.value && this.packagedForm.controls.caseCost.value !== 0) {
      let selectedFormat = this.refTable.filter(format => format.name === this.packagedForm.controls.format.value)[0]
      let cost: any = this.packagedForm.controls.caseCost.value;
      let unitCost: any = (cost / selectedFormat.value).toFixed(2);
      this.packagedForm.controls.unitCost.setValue(unitCost);

      //    this.updateTasterCost();

      if (this.packagedForm.controls.pour.value) {
        //   this.updatePourCost();
      }
    } else {
      this.packagedForm.controls.unitCost.setValue(0);
    }
  }

  updateMarginDollar() {
    if (this.packagedForm.controls.unitPrice.value && this.packagedForm.controls.unitCost.value) {
      this.packagedForm.controls.marginDollar.setValue(this.packagedForm.controls.unitPrice.value - this.packagedForm.controls.unitCost.value)

      this.updateMarginPercent();
    }
  }

  updateMarginPercent() {
    if (this.packagedForm.controls.marginDollar.value && this.packagedForm.controls.unitPrice.value) {
      this.packagedForm.controls.marginPercent.setValue(this.packagedForm.controls.marginDollar.value / this.packagedForm.controls.unitPrice.value)
    }
  }

  updatePackCost() {
    if (this.packagedForm.controls.packCount.value && this.packagedForm.controls.unitCost.value) {
      this.packagedForm.controls.packCost.setValue(this.packagedForm.controls.unitCost.value * this.packagedForm.controls.packCount.value);

      this.updatePackMarginDollar();
    }
  }

  updatePackMarginDollar() {
    if (this.packagedForm.controls.packPrice.value && this.packagedForm.controls.packCost.value) {
      this.packagedForm.controls.packMarginDollar.setValue(this.packagedForm.controls.packPrice.value - this.packagedForm.controls.packCost.value)

      this.updatePackMarginPercent();
    }
  }

  updatePackMarginPercent() {
    if (this.packagedForm.controls.packMarginDollar.value && this.packagedForm.controls.packPrice.value) {
      this.packagedForm.controls.packMarginPercent.setValue(this.packagedForm.controls.packMarginDollar.value / this.packagedForm.controls.packPrice.value)
    }
  }


  onSubmit() {
    this.dialogRef.close(this.packagedForm.value);
  }
}

