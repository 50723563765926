
<h3> Add A Product (Packaged) </h3>
<form [formGroup]="packagedForm" (ngSubmit)="onSubmit()">
    
    <mat-form-field appearance="outline">
        <mat-label for="first-name">Order Date: </mat-label>
        <input matInput id="first-name" type="text" placeholder="mm/dd/yyyy" formControlName="orderDate">
    </mat-form-field>

    <mat-form-field appearance="outline">
        <mat-label for="last-name">Invoiced Date: </mat-label>
        <input matInput id="last-name" type="text" placeholder="mm/dd/yyyy" formControlName="invoiced">
    </mat-form-field>

    <mat-form-field appearance="outline">
        <mat-label for="last-name">Quantity: </mat-label>
        <input matInput id="last-name" type="text" formControlName="qty">
    </mat-form-field>

    <mat-form-field appearance="outline">
        <mat-label for="last-name">Brewery: </mat-label>
        <input matInput id="last-name" type="text" formControlName="brewery">
    </mat-form-field>

    <mat-form-field appearance="outline">
        <mat-label for="last-name">Name: </mat-label>
        <input matInput id="last-name" type="text" formControlName="name">
    </mat-form-field>


    <mat-form-field appearance="outline">
        <mat-label for="last-name">Style: </mat-label>
        <input matInput id="last-name" type="text" formControlName="style">
    </mat-form-field>

    <mat-form-field appearance="outline">
        <mat-label for="last-name">Format: </mat-label>
        <mat-select formControlName="format" (change)="updateUnitCost()">
            <mat-option *ngFor="let ref of refTable" [value]="ref.name">{{ref.name}}</mat-option>
          </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline">
        <mat-label for="last-name">Case Cost: </mat-label>
        <span matPrefix>&nbsp; $ &nbsp;</span>
        <input matInput id="last-name" type="text" (change)="updateUnitCost()" formControlName="caseCost">
    </mat-form-field>

    <mat-form-field appearance="outline">
        <mat-label for="last-name">Pour: </mat-label>
        <input matInput id="last-name" type="text" readonly="true" formControlName="pour">
    </mat-form-field>

    <mat-form-field appearance="outline">
        <mat-label for="last-name">Unit Cost: </mat-label>
        <span matPrefix>&nbsp; $ &nbsp;</span>
        <input matInput id="last-name" [readonly]="true" type="text" formControlName="unitCost">
    </mat-form-field>

    <mat-form-field appearance="outline">
        <mat-label for="last-name">Unit Price: </mat-label>
        <span matPrefix>&nbsp; $ &nbsp;</span>
        <input matInput id="last-name" type="number" (change)="updateMarginDollar()" formControlName="unitPrice">
    </mat-form-field>

    <mat-form-field appearance="outline">
        <mat-label for="last-name">Margin Dollar: </mat-label>
        <span matPrefix>&nbsp; $ &nbsp;</span>
        <input matInput id="last-name" [readonly]="true" type="text" formControlName="marginDollar">
    </mat-form-field>

    <mat-form-field appearance="outline">
        <mat-label for="last-name">Margin Percent: </mat-label>
        <input matInput id="last-name" [readonly]="true" type="text" formControlName="marginPercent">
        <span matSuffix>&nbsp; % &nbsp;</span>
    </mat-form-field>

    <mat-form-field appearance="outline">
        <mat-label for="last-name">Pack Count: </mat-label>
        <input matInput id="last-name"  (change)="updatePackCost()" type="text" formControlName="packCount">
    </mat-form-field>

    <mat-form-field appearance="outline">
        <mat-label for="last-name">Pack Cost: </mat-label>
        <span matPrefix>&nbsp; $ &nbsp;</span>
        <input matInput id="last-name" [readOnly]="true" type="text" formControlName="packCost">
    </mat-form-field>

    <mat-form-field appearance="outline">
        <mat-label for="last-name">Pack Price </mat-label>
        <span matPrefix>&nbsp; $ &nbsp;</span>
        <input matInput id="last-name" (change)="updatePackCost()" type="text" formControlName="packPrice">
    </mat-form-field>

    <mat-form-field appearance="outline">
        <mat-label for="last-name">Pack Margin Dollar: </mat-label>
        <span matPrefix>&nbsp; $ &nbsp;</span>
        <input matInput id="last-name" [readonly]="true" type="text" formControlName="packMarginDollar">
    </mat-form-field>

    <mat-form-field appearance="outline">
        <mat-label for="last-name">Pack Margin Percent: </mat-label>
        <input matInput id="last-name" [readonly]="true" type="text" formControlName="packMarginPercent">
        <span matSuffix>&nbsp; % &nbsp;</span>
    </mat-form-field>

    <button type="submit" color="primary" mat-raised-button [disabled]="!packagedForm.valid">Submit</button>
</form>