<h1 class="stage-title"> <b> Summary </b></h1>
<table mat-table [dataSource]="onDeckdataSource" class="mat-elevation-z8 table-responsive">

  <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->

  <!-- Category Column -->
  <ng-container matColumnDef="Category">
    <th mat-header-cell *matHeaderCellDef> Category. </th>
    <td matRipple mat-cell *matCellDef="let element"> {{element.category}} </td>
    <td matRipple mat-footer-cell *matFooterCellDef> <b>Total</b> </td>
  </ng-container>

  <!-- KegCount Column -->
  <ng-container matColumnDef="On Deck">
    <th mat-header-cell *matHeaderCellDef> On Deck </th>
    <td matRipple mat-cell *matCellDef="let element"> <b>{{element.kegCount}} </b> </td>
    <td matRipple mat-footer-cell *matFooterCellDef> <b>{{getTotalCostOnDeck()}}</b> </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="onDeckDisplayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: onDeckDisplayedColumns;"></tr>
  <tr mat-footer-row *matFooterRowDef="onDeckDisplayedColumns"></tr>
</table>

<table mat-table [dataSource]="onOrderdataSource" class="mat-elevation-z8 table-responsive">

  <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->


  <!-- Category Column -->
  <ng-container matColumnDef="Category">
    <th mat-header-cell *matHeaderCellDef> Category. </th>
    <td matRipple mat-cell *matCellDef="let element"> {{element.category}} </td>
    <td matRipple mat-footer-cell *matFooterCellDef> <b>Total</b> </td>
  </ng-container>

  <!-- KegCount Column -->
  <ng-container matColumnDef="On Order">
    <th mat-header-cell *matHeaderCellDef> On Order </th>
    <td matRipple mat-cell *matCellDef="let element"> <b>{{element.kegCount}} </b> </td>
    <td matRipple mat-footer-cell *matFooterCellDef> <b>{{getTotalCostOnOrder()}}</b> </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="onOrderDisplayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: onOrderDisplayedColumns;"></tr>
  <tr mat-footer-row *matFooterRowDef="onOrderDisplayedColumns"></tr>
</table>


<table mat-table [dataSource]="bothTableDataSource" class="mat-elevation-z8 table-responsive">

  <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->

  <!-- Category Column -->
  <ng-container matColumnDef="Category">
    <th mat-header-cell *matHeaderCellDef> Category. </th>
    <td matRipple mat-cell *matCellDef="let element"> {{element.category}} </td>
    <td matRipple mat-footer-cell *matFooterCellDef> <b>Total</b> </td>
  </ng-container>

  <!-- KegCount Column -->
  <ng-container matColumnDef="On Order + On Deck">
    <th mat-header-cell *matHeaderCellDef> Order+Deck  </th>
    <td matRipple mat-cell *matCellDef="let element"> <b>{{element.kegCount}} </b> </td>
    <td matRipple mat-footer-cell *matFooterCellDef> <b>{{getBothTotalCostOnOrder()}}</b> </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="bothDisplayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: bothDisplayedColumns;"></tr>
  <tr mat-footer-row *matFooterRowDef="bothDisplayedColumns"></tr>
</table>

<table mat-table [dataSource]="summaryDataSource" class="mat-elevation-z8 table-responsive">

  <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->

  <!-- Goal Column -->
  <ng-container matColumnDef="goal">
    <th mat-header-cell *matHeaderCellDef> Goal. </th>
    <td matRipple mat-cell *matCellDef="let element">
      <mat-form-field class="goal">
        <mat-label for="last-name"> Goal </mat-label>
        <input matInput id="last-name" (blur)="updateGoal()" type="text" [(ngModel)]="element.goal">
    </mat-form-field>
    </td>
  </ng-container>

  <!-- ND To Order Column -->
  <ng-container matColumnDef="ndToOrder">
    <th mat-header-cell *matHeaderCellDef> ND To Order </th>
    <td matRipple mat-cell *matCellDef="let element"> <b> {{element.ndToOrder ?  element.ndToOrder: 0}} </b> </td>
  </ng-container>

  <!-- Category Column -->
  <ng-container matColumnDef="category">
    <th mat-header-cell *matHeaderCellDef> Category </th>
    <td matRipple mat-cell *matCellDef="let element"> {{element.category}}  </td>
  </ng-container>

    <!-- AVG Days Column -->
    <ng-container matColumnDef="avg_days">
      <th mat-header-cell *matHeaderCellDef> AVG Days </th>
      <td matRipple mat-cell *matCellDef="let element"> 
        {{getAvgDays(element.category)}}
      </td>
    </ng-container>

  <tr mat-header-row *matHeaderRowDef="summaryColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: summaryColumns;"></tr>
</table>