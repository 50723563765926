
<h3> Add A Product (Food Merchandise) </h3>
<form [formGroup]="foodMerchForm" (ngSubmit)="onSubmit()">
    
    <mat-form-field appearance="outline">
        <mat-label for="first-name">Order Date: </mat-label>
        <input matInput id="first-name" type="text" formControlName="orderDate">
    </mat-form-field>

    <mat-form-field appearance="outline">
        <mat-label for="last-name">Brand: </mat-label>
        <input matInput id="last-name" type="text" formControlName="brand">
    </mat-form-field>

    <mat-form-field appearance="outline">
        <mat-label for="last-name">Invoiced: </mat-label>
        <input matInput id="last-name" type="text" formControlName="invoiced">

    </mat-form-field>
    <mat-form-field appearance="outline">
        <mat-label for="last-name">Received: </mat-label>
        <input matInput id="last-name" type="number" formControlName="received">

    </mat-form-field>
    <mat-form-field appearance="outline">
        <mat-label for="last-name">Quantity Case: </mat-label>
        <input matInput id="last-name" type="text" (change)="updateUnitCost()" formControlName="qtyCase">
    </mat-form-field>

    <mat-form-field appearance="outline">
        <mat-label for="last-name">Cases: </mat-label>
        <input matInput id="last-name" type="number" formControlName="cases">
    </mat-form-field>

    <mat-form-field appearance="outline">
        <mat-label for="last-name">Product: </mat-label>
        <input matInput id="last-name" type="number" formControlName="product">
    </mat-form-field>


    <mat-form-field appearance="outline">
        <mat-label for="last-name">Type: </mat-label>
        <input matInput id="last-name" type="number" formControlName="type">
    </mat-form-field>

    <mat-form-field appearance="outline">
        <mat-label for="last-name">Case Cost: </mat-label>
        <span matPrefix>&nbsp; $ &nbsp;</span>
        <input matInput id="last-name" readonly="true" type="text" (change)="updateUnitCost()" formControlName="caseCost">
    </mat-form-field>

    <mat-form-field appearance="outline">
        <mat-label for="last-name">Unit Cost: </mat-label>
        <span matPrefix>&nbsp; $ &nbsp;</span>
        <input matInput id="last-name" type="text" formControlName="unitCost">
    </mat-form-field>

    <mat-form-field appearance="outline">
        <mat-label for="last-name">Unit Price: </mat-label>
        <span matPrefix>&nbsp; $ &nbsp;</span>
        <input matInput id="last-name" readonly="true" type="number" (change)="updateMarginDollar()" formControlName="unitPrice">
    </mat-form-field>

    <mat-form-field appearance="outline">
        <mat-label for="last-name">Margin Dollar: </mat-label>
        <span matPrefix>&nbsp; $ &nbsp;</span>
        <input matInput id="last-name" readonly="true" type="text" formControlName="marginDollar">
    </mat-form-field>

    <mat-form-field appearance="outline">
        <mat-label for="last-name">Margin Percent: </mat-label>
        <input matInput id="last-name" readonly="true" type="text" formControlName="marginPercent">
        <span matSuffix>&nbsp; % &nbsp;</span>
    </mat-form-field>

    <button type="submit" color="primary" mat-raised-button [disabled]="!foodMerchForm.valid">Submit</button>
</form>