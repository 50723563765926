import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { AngularFireDatabase, AngularFireObject } from '@angular/fire/compat/database';

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss']
})
export class SummaryComponent implements AfterViewInit {
  onDeck: AngularFireObject<any>;
  onOrder: AngularFireObject<any>;
  onKicked: AngularFireObject<any>;
  summary: AngularFireObject<any>;
  onDeckCategories: string[] = [];
  onDeckKegCount: object[] = [];
  onDeckDisplayedColumns: string[] = ['Category', 'On Deck'];
  onOrderDisplayedColumns: string[] = ['Category', 'On Order'];
  bothDisplayedColumns: string[] = ['Category', 'On Order + On Deck'];
  summaryColumns: string[] = ['goal', 'ndToOrder', 'category', 'avg_days'];
  onDeckdataSource: any;
  onOrderCategories: string[] = [];
  onOrderKegCount: object[] = [];
  onOrderdataSource: any;
  addBothTables: object[] = [];
  addBothTablesCategories: string[] = [];
  bothTablesKegCount: object[] = [];
  bothTableDataSource: any;
  summaryDataSource: object[] = [];
  onKickedCategories: string[] = [];
  avg_days: object[] = [];
  
  constructor(private db: AngularFireDatabase) {
    this.onDeck = db.object('On Deck');
    this.onOrder = db.object('On Order');
    this.summary = db.object('summary');
    this.onKicked = db.object('Kicked');
  }

  compareByRank(a: any, b: any) {
    if (a.rank < b.rank) {
      return -1;
    }
    if (a.rank > b.rank) {
      return 1;
    }
    return 0;
  }

  updateGoal() {
    this.summary.set(this.summaryDataSource);
  }

  updateAVGDays() {
    this.summary.set(this.summaryDataSource);
  }

  getTotalCostOnDeck() {
    return this.onDeckKegCount.map((t: any) => t.kegCount).reduce((acc, value) => acc + value, 0);
  }

  getTotalCostOnOrder() {
    return this.onOrderKegCount.map((t: any) => t.kegCount).reduce((acc, value) => acc + value, 0);
  }

  getBothTotalCostOnOrder() {
    return this.bothTablesKegCount.map((t: any) => t.kegCount).reduce((acc, value) => acc + value, 0);
  }

  getGoalTotal() {
    return this.summaryDataSource.map((t: any) => t.goal).reduce((acc, value) => acc + value, 0);
  }

  getAvgDays(category: string) {
    let avg: any = this.avg_days.filter((row: any) => row.category === category)[0]
   return avg ? avg.avg_days : 0;
  }


  ngAfterViewInit(): void {

    this.summary.valueChanges().subscribe((value: any[]) => {
      this.summaryDataSource = value;
    })

    this.onKicked.valueChanges().subscribe((value: any[]) => {
      console.log(value);
      value.forEach((row) => {
        if (this.onKickedCategories.indexOf(row.type) === -1 && row.type !== undefined) {
          this.onKickedCategories.push(row.type);
          this.avg_days.push({'category': row.type, 'avg_days': row.avgDays});
        }
      })
    })
    
    this.onDeck.valueChanges().subscribe((value: any[]) => {
      for (let i = 0; i < value.length; i++) {        
        if (this.onDeckCategories.indexOf(value[i].category) === -1 && value[i].category !== undefined) {
          this.onDeckCategories.push(value[i].category);

          let rank;
          
          this.summaryDataSource.forEach((data: any) => {
            if(value[i].category.includes('-')) {
              value[i].category = value[i].category.replace("-", "/");
            }

            if(data.category === value[i].category) {
              rank = data.rank;
            }
          })

          this.onDeckKegCount.push({'category': value[i].category, 'kegCount': value[i].kegCount, 'rank': rank});
        } else {
          this.onDeckKegCount.forEach((row: any) => {
            if(row.category === value[i].category) {
              row.kegCount = row.kegCount + value[i].kegCount
            }
          })
        }
      }

      this.onDeckdataSource = this.onDeckKegCount;
      this.onDeckdataSource = this.onDeckdataSource.sort(this.compareByRank);
      this.addBothTables = this.addBothTables.concat(this.onDeckKegCount);
    })

    this.onOrder.valueChanges().subscribe((value: any[]) => {
      for (let i = 0; i < value.length; i++) {        
        if (this.onOrderCategories.indexOf(value[i].category) === -1 && value[i].category !== undefined) {
          this.onOrderCategories.push(value[i].category);

          let rank;
          
          this.summaryDataSource.forEach((data: any) => {
            if(value[i].category.includes('-')) {
              value[i].category = value[i].category.replace("-", "/");
            }
            if(data.category === value[i].category) {
              rank = data.rank;
            }
          })

          this.onOrderKegCount.push({'category': value[i].category, 'kegCount': value[i].kegCount, 'rank': rank});

        } else {
          this.onOrderKegCount.forEach((row: any) => {
            if(row.category === value[i].category) {
              row.kegCount = row.kegCount + value[i].kegCount
            }
          })
        }
      }

    this.onOrderdataSource = this.onOrderKegCount;
    this.onOrderdataSource = this.onOrderdataSource.sort(this.compareByRank);

    this.addBothTables= this.addBothTables.concat(this.onOrderKegCount);


    this.addBothTables.forEach((line: any) => {
      if (this.addBothTablesCategories.indexOf(line.category) === -1 && line.category !== undefined) {
        this.addBothTablesCategories.push(line.category);
        this.bothTablesKegCount.push({'category': line.category, 'kegCount': line.kegCount});
      } else {
        this.bothTablesKegCount.forEach((row: any) => {
          if(row.category === line.category) {
            row.kegCount = row.kegCount + line.kegCount
          }
        })
      }
    })

    this.summaryDataSource.forEach((summaryData: any) => {
      this.bothTablesKegCount.forEach((tableData: any) => {
        if(summaryData.category === tableData.category) {
          summaryData.ndToOrder = summaryData.goal - tableData.kegCount;
        }
      })
    })

    this.bothTableDataSource = this.bothTablesKegCount;
    })
  }

}
