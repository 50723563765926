import { Component } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-ootb-navigation',
  templateUrl: './ootb-navigation.component.html',
  styleUrls: ['./ootb-navigation.component.scss']
})
export class OotbNavigationComponent {

  activePage: string;

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  constructor(private breakpointObserver: BreakpointObserver, private router: Router) {

    this.activePage = window.location.pathname.substring(1).replace("-", " ");
  }

}
