import { NgModule, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MatNativeDateModule } from '@angular/material/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AngularFireModule } from '@angular/fire/compat';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { OotbDashboardComponent } from './ootb-dashboard/ootb-dashboard.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { LayoutModule } from '@angular/cdk/layout';
import { OotbNavigationComponent } from './ootb-navigation/ootb-navigation.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { AddAProduct, BulkDeleteDialog, BulkUpdateDialog, DeleteOrderDialog, OnOrderComponent, UpdateInvoicedDate, UpdateOrderDialog, UpdateQuantityDialog } from './on-order/on-order.component';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatDialogModule} from '@angular/material/dialog';
import {MatSelectModule} from '@angular/material/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { environment } from 'src/environments/environment';
import { OnDeckComponent } from './on-deck/on-deck.component';
import { OnTapComponent, UpdateTapComponent } from './on-tap/on-tap.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { KickedComponent } from './kicked/kicked.component';
import { AddAProductToMaster, MasterComponent } from './master/master.component';
import { AddAProductToPackaged, PackagedComponent } from './packaged/packaged.component';
import { AddAProductToFoodMerch, FoodMerchComponent, ReOrderDialog } from './food-merch/food-merch.component';
import { AddAProductToWine, WineComponent } from './wine/wine.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatRadioModule} from '@angular/material/radio';
import { SummaryComponent } from './summary/summary.component';
import {MatRippleModule} from '@angular/material/core';
import * as Sentry from "@sentry/angular-ivy";
import { Router } from '@angular/router';

@NgModule({
  declarations: [
    AppComponent,
    OotbDashboardComponent,
    OotbNavigationComponent,
    OnOrderComponent,
    DeleteOrderDialog,
    UpdateOrderDialog,
    UpdateQuantityDialog,
    UpdateInvoicedDate,
    AddAProduct,
    OnDeckComponent,
    OnTapComponent,
    BulkDeleteDialog,
    BulkUpdateDialog,
    UpdateTapComponent,
    KickedComponent,
    MasterComponent,
    AddAProductToMaster,
    PackagedComponent,
    FoodMerchComponent,
    WineComponent,
    ReOrderDialog,
    AddAProductToPackaged,
    AddAProductToWine,
    AddAProductToFoodMerch,
    SummaryComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase, 'out-of-the-barrel'),
    BrowserAnimationsModule,
    MatFormFieldModule,
    MatGridListModule,
    MatCardModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    LayoutModule,
    MatToolbarModule,
    MatSidenavModule,
    MatListModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatInputModule,
    MatDialogModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    MatRadioModule,
    MatRippleModule
  ],
  exports: [],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: true,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(trace: Sentry.TraceService) {}
}
