import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, Component, Inject, ViewChild } from '@angular/core';
import { AngularFireDatabase, AngularFireObject } from '@angular/fire/compat/database';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Papa } from 'ngx-papaparse';
import { ReOrderDialog } from '../food-merch/food-merch.component';
import { BulkDeleteDialog, DeleteOrderDialog, UpdateInvoicedDate, UpdateQuantityDialog } from '../on-order/on-order.component';
import { WineDataSource, WineItem } from './wine-datasource';

@Component({
  selector: 'app-wine',
  templateUrl: './wine.component.html',
  styleUrls: ['./wine.component.scss']
})
export class WineComponent implements AfterViewInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatTable) table!: MatTable<any>;
  dataSource: MatTableDataSource<WineDataSource>;
  selection = new SelectionModel<any>(true, []);
  search: string ='';

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = [
    "select",
    "reorder",
    "orderDate",
    "invoiced",
    "received",
    "qty",
    "winery",
    "nameStyle",
    "qtyCase",
    "caseCost",
    "bottleCost",
    "costPour",
    "pricePour",
    "marginDollar",
    "marginPercent",
    "flightCost",
    "flightPrice",
    "flightMarginDollar",
    "flightMarginPercent",
    "bottlePrice",
    "bottleMarginDollar",
    "bottleMarginPercent"
  ];
  wine: AngularFireObject<any>;
  tempCacheQuantity: any;
  cacheInvoicedDate: any;

  constructor(private db: AngularFireDatabase, public dialog: MatDialog, public papa: Papa) {
    this.dataSource = new MatTableDataSource();
    this.wine = db.object('wine');
  }

  reorderProduct(row: any) {
    const dialogRef = this.dialog.open(ReOrderDialog, {
      width: '500px',
      data: {
        row: row
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        let newProduct = Object.assign({}, row);
        newProduct.orderDate = new Date().toLocaleDateString(); 
        newProduct.invoiced = null;
      this.dataSource.data.push(newProduct);
       this.wine.set(this.dataSource.data);
      }
    });
  }

  updateCacheQuantity(row: any) {
    this.tempCacheQuantity = row.qty;
  }

  updateInvoicedDate(row: any) {

    if (this.cacheInvoicedDate != row.invoiced) {
      const dialogRef = this.dialog.open(UpdateInvoicedDate, {
        width: '500px',
        data: {
          row: row
        }
      });

      dialogRef.afterClosed().subscribe(result => {

        if (result) {
          this.wine.set(this.dataSource.data);
        } else {
          row.invoiced = this.cacheInvoicedDate;
        }
      });
    }
  }

  updateCacheInvoicedDate(row: any) {
    this.cacheInvoicedDate = row.invoiced;
  }

  updateQuantity(row: any) {
    if (row.qty !== this.tempCacheQuantity) {
      const dialogRef = this.dialog.open(UpdateQuantityDialog, {
        width: '500px',
        data: {
          row: row
        }
      });

      dialogRef.afterClosed().subscribe(result => {

        if (result) {
          this.wine.set(this.dataSource.data);
        } else {
          row.qty = this.tempCacheQuantity;
        }
      });
    }
  }

  public changeListener(event: any){

    let files = event.target.files;

    if(files && files.length > 0) {
       let file : any = files.item(0); 
         let reader: FileReader = new FileReader();
         reader.readAsText(file);
         reader.onload = (e) => {
            let csv: string = reader.result as string;
            
            this.papa.parse(csv, {
              header: true,
              complete: (result: any) => {
                  result.data.forEach((row:any, i: any) => {

                    if (row.qtyCase && row.caseCost) {

                      row.bottleCost = (row.caseCost.split("$")[1] / row.qtyCase.split('case')[0]).toFixed(2);
                
                      if(row.bottleCost) {
                        if(row.bottleCost) {
                          row.costPour = row.bottleCost*0.25;
                        }

                        if(row.bottleCost) {
                          row.flightCost = row.bottleCost*0.2;
                        }
                      }
                    } else {
                      row.bottleCost = 0;
                    }

                    if(row.pricePour && row.costPour) {
                      row.marginDollar = row.pricePour.split('$')[1] - row.costPour;
                      
                      if(row.marginDollar && row.pricePour) {
                        row.marginPercent = row.marginDollar / row.pricePour.split('$')[1];
                      }
                    }

                    if(row.flightPrice && row.flightCost) {
                      row.flightMarginDollar = row.flightPrice.split('$')[1] - row.flightCost
                      
                      if(row.flightMarginDollar && row.flightPrice) {
                        row.flightMarginPercent = row.flightMarginDollar / row.flightPrice.split('$')[1]
                      }
                    }

                    if(row.bottlePrice && row.bottleCost) {
                      row.bottleMarginDollar = (row.bottlePrice.split('$')[1] - row.bottleCost);
                      
                      if(row.bottleMarginDollar && row.bottlePrice) {
                        row.bottleMarginPercent =  row.bottleMarginDollar / row.bottlePrice.split('$')[1];
                      }
                    }

                    if(i === result.data.length - 1) {
                      this.dataSource.data = this.dataSource.data.concat(result.data);
                      this.wine.set(this.dataSource.data);
                     }
                  });
              }
          });

         }
      }
  }

  updateRecieved() {
    this.wine.set(this.dataSource.data);
  }


  applyFilter(search: string) {

    this.dataSource.filter = search.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  deleteRow(row: any) {
    const dialogRef = this.dialog.open(DeleteOrderDialog, {
      width: '250px',
      data: {
        row: row
      }
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result) {
        this.deleteProduct(row);
      }
    });
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }
  
  addAProductToWine() {
    const dialogRef = this.dialog.open(AddAProductToWine);

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.dataSource.data.push(result);
        this.wine.set(this.dataSource.data);
      }
    });
  }

  bulkDelete() {
    const dialogRef = this.dialog.open(BulkDeleteDialog, {
      width: '500px',
      data: {
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.selection.selected.forEach((selected: any) => {
          this.deleteProduct(selected);
        })
      }
    });
  }

  deleteProduct(row: any) {
    let ind = this.dataSource.data.findIndex(obj => { return obj === row });

    if (ind !== -1) {
      this.dataSource.data.splice(ind, 1);
      this.table.dataSource = this.dataSource.data;
      this.wine.set(this.dataSource.data);
    }
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.wine.valueChanges().subscribe((value: any[]) => {
      this.dataSource.data = value;
      this.table.dataSource = this.dataSource;
      this.dataSource.paginator = this.paginator;
    })
  }
}

@Component({
  selector: 'add-product-to-wine',
  templateUrl: 'add-a-product-to-wine.html',
  styleUrls: ['./add-a-product-wine.component.scss']
})

export class AddAProductToWine {
  wineForm = new FormGroup({
    orderDate: new FormControl('', [Validators.required]),
    nameStyle: new FormControl('', [Validators.required]),
    received: new FormControl('', [Validators.required]),
    invoiced: new FormControl('', [Validators.required]),
    qty: new FormControl<number>(0, [Validators.required]),
    winery: new FormControl('', [Validators.required]),
    qtyCase: new FormControl<number>(0, [Validators.required]),
    bottleCost: new FormControl<number>(0, [Validators.required]),
    caseCost: new FormControl<number>(0, [Validators.required]),
    costPour: new FormControl<number>(0, [Validators.required]),
    pricePour: new FormControl<number>(0, [Validators.required]),
    flightCost: new FormControl<number>(0, [Validators.required]),
    marginDollar: new FormControl<number>(0, [Validators.required]),
    marginPercent: new FormControl<number>(0, [Validators.required]),
    flightPrice: new FormControl<number>(0, []),
    flightMarginDollar: new FormControl<number>(0, [Validators.required]),
    flightMarginPercent: new FormControl<number>(0, [Validators.required]),
    bottlePrice: new FormControl<number>(0, [Validators.required]),
    bottleMarginDollar: new FormControl<number>(0, [Validators.required]),
    bottleMarginPercent: new FormControl<number>(0, [Validators.required]),
  });

  refTable = [
    { name: "sixtel", value: 595, kegCount: 1 },
    { name: "half", value: 1786 , kegCount: 3 },
    { name: "quarter", value: 893, kegCount: 2 },
    { name: "30l", value: 913, kegCount: 1 },
    { name: "50l", value: 1521, kegCount: 3 },
    { name: "24case", value: 24, kegCount: 0 },
    { name: "18case", value: 18, kegCount: 0 },
    { name: "12case", value: 12, kegCount: 0 },
    { name: "6case", value: 6, kegCount: 0 }];

  pourSelection = [
    { name: "Tulip", value: 12 },
    { name: "Pint", value: 17 },
    { name: "Nonic", value: 17 },
    { name: "8oz", value: 8 },
    { name: "Wine", value: 8 },
    { name: "Bottle/Can", value: 1 }
  ]

  updateBottleCost() {
    if (this.wineForm.controls.qtyCase.value && this.wineForm.controls.caseCost.value) {

      this.wineForm.controls.bottleCost.setValue(this.wineForm.controls.caseCost.value / this.wineForm.controls.qtyCase.value);

      if(this.wineForm.controls.bottleCost.value) {
        this.updateCostPour();
        this.updateFlightCost();
      }
    } else {
      this.wineForm.controls.bottleCost.setValue(0);
    }
  }

  updateCostPour() {
    if(this.wineForm.controls.bottleCost.value) {
      this.wineForm.controls.costPour.setValue(this.wineForm.controls.bottleCost.value*0.25);
    }
  }

  updateFlightCost() {
    if(this.wineForm.controls.bottleCost.value) {
      this.wineForm.controls.flightCost.setValue(this.wineForm.controls.bottleCost.value*0.2);
    }
  }

  updateMarginDollar() {
    if(this.wineForm.controls.pricePour.value && this.wineForm.controls.costPour.value) {
      this.wineForm.controls.marginDollar.setValue(this.wineForm.controls.pricePour.value - this.wineForm.controls.costPour.value)
      
      this.updateMarginPercent();
    }
  }

  updateMarginPercent() {
    if(this.wineForm.controls.marginDollar.value && this.wineForm.controls.pricePour.value) {
      this.wineForm.controls.marginPercent.setValue(this.wineForm.controls.marginDollar.value / this.wineForm.controls.pricePour.value)
    }
  }

  updateMarginFlightDollar() {
    if(this.wineForm.controls.flightPrice.value && this.wineForm.controls.flightCost.value) {
      this.wineForm.controls.flightMarginDollar.setValue(this.wineForm.controls.flightPrice.value - this.wineForm.controls.flightCost.value)
      
      this.updateMarginFlightPercent();
    }
  }

  updateMarginFlightPercent() {
    if(this.wineForm.controls.flightMarginDollar.value && this.wineForm.controls.flightPrice.value) {
      this.wineForm.controls.flightMarginPercent.setValue(this.wineForm.controls.flightMarginDollar.value / this.wineForm.controls.flightPrice.value)
    }
  }

  updateMarginBottleDollar() {
    if(this.wineForm.controls.bottlePrice.value && this.wineForm.controls.bottleCost.value) {
      this.wineForm.controls.bottleMarginDollar.setValue(this.wineForm.controls.bottlePrice.value - this.wineForm.controls.bottleCost.value)
      
      this.updateMarginBottlePercent();
    }
  }

  updateMarginBottlePercent() {
    if(this.wineForm.controls.bottleMarginDollar.value && this.wineForm.controls.bottlePrice.value) {
      this.wineForm.controls.bottleMarginPercent.setValue(this.wineForm.controls.bottleMarginDollar.value / this.wineForm.controls.bottlePrice.value)
    }
  }


  constructor(public dialogRef: MatDialogRef<AddAProductToWine>, @Inject(MAT_DIALOG_DATA) public data: any) {

  }


  onSubmit() {
    this.dialogRef.close(this.wineForm.value);
  }
}
