<mat-form-field class="search-product">
  <mat-label>Search</mat-label>
  <input matInput (keyup)="applyFilter(search)" [(ngModel)]="search"
    placeholder="Search by any column. Eg: cost, margin, date" #input>
  <button *ngIf="search" matSuffix mat-icon-button aria-label="Clear" (click)="search=''; applyFilter(search)">
    <mat-icon>close</mat-icon>
  </button>
</mat-form-field>

<button class="group-button" mat-button [matMenuTriggerFor]="menu">Actions</button>
<mat-menu #menu="matMenu">
  <button mat-menu-item (click)="addAProduct()">Add A Product <mat-icon>plus_one</mat-icon></button>
  <button mat-menu-item (click)="bulkDelete()">delete <mat-icon>delete</mat-icon></button>
  <button mat-menu-item (click)="fileInput.click()">Import CSV</button>
  <input hidden (change)="changeListener($event)" #fileInput type="file">
</mat-menu>

<div class="mat-elevation-z8">
  <table mat-table class="table-responsive" matSort aria-label="Elements">
    <!-- Checkbox Column -->
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox (change)="$event ? toggleAllRows() : null" [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
          [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
        </mat-checkbox>
      </td>
    </ng-container>

    <!-- Id Column -->
    <ng-container matColumnDef="abv">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Abv</th>
      <td mat-cell *matCellDef="let row">{{convertToPercent(row.abv)}}%</td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
      <td mat-cell *matCellDef="let row">{{row.name}}</td>
    </ng-container>


    <!-- Name Column -->
    <ng-container matColumnDef="tastingNotes">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Tasting Notes</th>
      <td mat-cell *matCellDef="let row">{{row.tastingNotes}}</td>
    </ng-container>

    <!-- Brewery Column -->
    <ng-container matColumnDef="brewery">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Brewery</th>
      <td mat-cell *matCellDef="let row">{{row.brewery}}</td>
    </ng-container>

    <!-- Cost Column -->
    <ng-container matColumnDef="cost">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Cost</th>
      <td mat-cell *matCellDef="let row">${{row.cost}}</td>
    </ng-container>

    <!-- Format Column -->
    <ng-container matColumnDef="format">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Format</th>
      <td mat-cell *matCellDef="let row">{{row.format}}</td>
    </ng-container>

    <!-- Format Column -->
    <ng-container matColumnDef="style">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Style</th>
      <td mat-cell *matCellDef="let row">{{row.style}}</td>
    </ng-container>

    <!-- lightAleLager Column -->
    <ng-container matColumnDef="lightAleLager">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Category</th>
      <td mat-cell *matCellDef="let row">{{row.lightAleLager}}</td>
    </ng-container>

    <!-- Margin Dollar Column -->
    <ng-container matColumnDef="marginDollar">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Pour Margin ($)</th>
      <td mat-cell *matCellDef="let row">${{toFixed2(row.marginDollar)}}</td>
    </ng-container>

    <!-- Margin Percent Column -->
    <ng-container matColumnDef="marginPercent">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Pour Margin(%)</th>
      <td mat-cell *matCellDef="let row">{{toFixed2(row.marginPercent*100)}}%</td>
    </ng-container>

    <!-- Taster Cost Column -->
    <ng-container matColumnDef="tasterCost">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Taster Cost</th>
      <td mat-cell *matCellDef="let row">${{toFixed2(row.tasterCost)}}</td>
    </ng-container>

    <!-- Taster Price Column -->
    <ng-container matColumnDef="tasterPrice">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Taster Price</th>
      <td mat-cell *matCellDef="let row"><b>${{toFixed2(row.tasterPrice)}}</b></td>
    </ng-container>

    <!-- Margin Dollar Column -->
    <ng-container matColumnDef="tasterMarginDollar">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Taster Margin ($)</th>
      <td mat-cell *matCellDef="let row">${{toFixed2(row.tasterMarginDollar)}}</td>
    </ng-container>

    <!-- Margin Percent Column -->
    <ng-container matColumnDef="tasterMarginPercent">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Taster Margin (%)</th>
      <td mat-cell *matCellDef="let row">{{toFixed2(row.tasterMarginPercent*100)}}%</td>
    </ng-container>

    <!-- Pour Column -->
    <ng-container matColumnDef="pour">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Pour</th>
      <td mat-cell *matCellDef="let row"><b>{{row.pour}}</b></td>
    </ng-container>

    <!-- Pour Cost Column -->
    <ng-container matColumnDef="pourCost">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Pour Cost</th>
      <td mat-cell *matCellDef="let row">${{toFixed2(row.pourCost)}}</td>
    </ng-container>

    <!-- Pour Price Column -->
    <ng-container matColumnDef="pourPrice">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Pour Price</th>
      <td mat-cell *matCellDef="let row"><b>${{toFixed2(row.pourPrice)}}</b></td>
    </ng-container>

    <!-- Unit Cost Column -->
    <ng-container matColumnDef="unitCost">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Unit Cost</th>
      <td mat-cell *matCellDef="let row">${{toFixed2(row.unitCost)}}</td>
    </ng-container>

    <!-- Action Column -->
    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Action</th>
      <td mat-cell *matCellDef="let row;let i = index"><button (click)="deleteRow(row)" mat-button
          color="warn">delete</button></td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <mat-paginator #paginator [length]="dataSource.data.length" [pageIndex]="0" [pageSize]="100"
    [pageSizeOptions]="[5, 10, 20]" aria-label="Select page">
  </mat-paginator>
</div>